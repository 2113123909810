import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Country } from '../models/country.model';
import { Language } from '../models/language.model';
import { Salutation } from '../models/salutation.model';
import { LanguageService } from '../services/language.service';
import { SalutationService } from '../services/salutation.service';
import { CountryService } from '../services/country.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { User } from '../models/user.model';
import { Router } from '@angular/router';
import { MessagesService } from '../services/messages.service';
import { SpinnerService } from '../services/spinner.service';
import { ParameterService } from '../services/parameter.service';
import { RedirectService } from '../services/redirect.service';
import { FirebaseFunctionsService } from '../services/firebase-functions.service';
import { PhoneValidatorService } from '../services/phone-validator.service';
import { AuthService } from '../services/auth.service';
import { ProviderService } from '../services/provider.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

interface FormData {
  email: FormControl<string>;
  signupNewsletter: FormControl<boolean>;
  language: FormControl<string>;
  salutation: FormControl<string>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  street: FormControl<string>;
  number: FormControl<string>;
  zipCode: FormControl<string>;
  city: FormControl<string>;
  country: FormControl<string>;
  phone: FormControl<string>;
}

@Component({
  selector: 'app-profile-federated',
  templateUrl: './profile-federated.component.html',
  styleUrls: ['./profile-federated.component.scss']
})
export class ProfileFederatedComponent implements OnInit {
  @ViewChild('content') template: TemplateRef<any>;
  profileForm: FormGroup<FormData>;
  isEmailVerified = true;
  bsModalRef: BsModalRef;
  email: string;
  language: string;
  deleteRequested = false;
  providerIds: string[] = [];
  languages: Language[] = [];
  salutations: Salutation[] = [];
  countries: Country[] = [];

  constructor(
    private auth: AuthService,
    private params: ParameterService,
    private formBuilder: FormBuilder,
    languageService: LanguageService,
    salutationService: SalutationService,
    countryService: CountryService,
    private modalService: BsModalService,
    private router: Router,
    private messages: MessagesService,
    private spinner: SpinnerService,
    private functions: FirebaseFunctionsService,
    private redirect: RedirectService,
    private phoneValidator: PhoneValidatorService,
    private providerService: ProviderService,
  ) {
    this.languages = languageService.items;
    this.salutations = salutationService.items;
    this.countries = countryService.items;
  }

  ngOnInit() {
    this.messages.pushWarningMessage('ProfileFederated.NotComplete');
    this.deleteRequested = false;
    this.buildForm();
  }

  private async buildForm(): Promise<void> {
    this.providerIds = await this.providerService.getProviderIds();
    const currentUser = this.auth.getCurrentFirebaseUser();
    const names = this.getNamesForCurrentUser(currentUser.displayName);

    this.profileForm = this.formBuilder.group({
      email: [{ value: currentUser.email, disabled: true }, []],
      signupNewsletter: [true, []],
      language: ['', [Validators.required]],
      salutation: ['', [Validators.required]],
      firstName: [names.firstName, [Validators.required]],
      lastName: [names.lastName, [Validators.required]],
      street: ['', [Validators.required]],
      number: ['', [Validators.required]],
      zipCode: ['', [Validators.required]],
      city: ['', [Validators.required]],
      country: ['', [Validators.required]],
      phone: ['', [Validators.pattern(/^$|^(\+?[0-9]{2,3})?[0-9]{6,}$/)]]
    }, { validators: this.phoneValidator.validator });

    const u = await this.auth.getUserFromStore();

    if (!u) {
      if (localStorage.getItem('termsAccepted') !== 'true') {
        this.bsModalRef = this.modalService.show(this.template, { backdrop: 'static', keyboard: false });
      }
      return;
    }

    // this.isEmailVerified = u.confirmed ?? false;

    if (currentUser.emailVerified) {
      if (!u.confirmed) {
        this.functions.confirmUserAccountEmail(currentUser.uid);
      }
    }

    let language = '';
    if (u.language) {
      language = u.language;
    }
    let country = '';
    if (u.country) {
      country = u.country;
    }

    this.profileForm.patchValue({
      email: u.email,
      signupNewsletter: u.signedUpNewsletter,
      language,
      salutation: u.salutation,
      firstName: u.firstName,
      lastName: u.lastName,
      street: u.street,
      number: u.number,
      zipCode: u.zipCode,
      city: u.city,
      country,
      phone: u.phone
    });

    if (!this.profileForm.invalid && this.isEmailVerified) {
      this.messages.clear();
    }
  }

  openChangeEmail() {
    this.router.navigate(['/change-email']);
  }

  private getNamesForCurrentUser(displayName: string): { firstName: string, lastName: string } {
    const result = {
      firstName: '',
      lastName: ''
    };

    if (!displayName) {
      return result;
    }

    const splits = displayName.split(' ');
    if (splits.length !== 2) {
      return result;
    }

    result.firstName = splits[0];
    result.lastName = splits[1];

    return result;
  }

  async updateAccount() {
    if (this.profileForm.invalid) {
      return;
    }

    this.spinner.show(true);

    let phone = this.profileForm.value.phone;

    if (!phone) {
      phone = '';
    }

    const currentUser = this.auth.getCurrentFirebaseUser();

    const account: User = {
      uid: currentUser.uid,
      email: currentUser.email,
      signedUpNewsletter: this.profileForm.value.signupNewsletter,
      acceptedTermsAndConditions: true,
      acceptedPrivacyPolicy: true,
      language: this.profileForm.value.language,
      accountType: this.getAccountType(currentUser.providerData[0].providerId),

      salutation: this.profileForm.value.salutation,
      firstName: this.profileForm.value.firstName,
      lastName: this.profileForm.value.lastName,
      street: this.profileForm.value.street,
      number: this.profileForm.value.number,
      zipCode: this.profileForm.value.zipCode,
      city: this.profileForm.value.city,
      country: this.profileForm.value.country,
      phone
    };
    await this.functions.createUserAccount(account, { merge: true })
      .then(async _ => {
        this.messages.clear();

        if (!this.params.isRedirectAuthFlow()) {
          this.router.navigate(['/profile']);
          return;
        }

        this.spinner.show(true);

        const clientId = this.params.get('client_id').value;
        const username = currentUser.email;
        const getAttributesModel = await this.functions.getAttributesForUser(clientId, username);
        const scopes = '[' + getAttributesModel.data.attributes.scopes.toString() + ']';
        const xDsTrackingId = getAttributesModel.data.attributes.xDsTrackingId;
        if (!getAttributesModel.data.attributes.isTrusted) {
          const scopesParameter = { key: 'scopes', value: scopes };
          const xDsTrackingIdParameter = { key: 'x-ds-tracking-id', value: xDsTrackingId };
          this.router.navigateByUrl('/consent' + this.params.getRequestQueryParametersString([scopesParameter, xDsTrackingIdParameter]));
          return;
        }
        if (this.params.get('response_type').value === 'token') {
          const grantType = 'implicit';
          const redirectUri = await this.functions.implicitToken(
            clientId,
            xDsTrackingId,
            this.params.get('appinstance_id').value,
            this.params.get('appinstance_name').value,
            this.params.get('redirect_uri').value,
            scopes,
            this.params.get('response_type').value,
            grantType,
          );

          this.redirect.ToClientUri(redirectUri);

        }
        else {
          const redirectUri = await this.functions.authorizeUser(
            clientId,
            xDsTrackingId,
            this.params.get('appinstance_id').value,
            this.params.get('appinstance_name').value,
            this.params.get('redirect_uri').value,
            scopes,
            this.params.get('response_type').value,
            this.params.get('state').value
          );

          this.redirect.ToClientUri(redirectUri);
        }
      })
      .catch(error => {
        // TODO: Show info to user.
        console.log(error);
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  cancelTermsModal() {
    this.auth.signOut();
    this.bsModalRef.hide();
    this.messages.clear();
    this.router.navigate(['/']);
  }

  async acceptTermsModal(): Promise<void> {
    const currentUser = this.auth.getCurrentFirebaseUser();

    const account: User = {
      uid: currentUser.uid,
      email: currentUser.email,
      acceptedTermsAndConditions: true,
      acceptedPrivacyPolicy: true,
      accountType: this.getAccountType(currentUser.providerData[0].providerId)
    };

    try {
      await this
        .functions
        .createUserAccount(account);
    } catch (error) {
      // TODO: Show info to user.
      console.log(error);
    }

    this.bsModalRef.hide();
  }

  getAccountType(providerId: string): string {
    switch (providerId) {
      case 'google.com':
        return 'Google';
      case 'facebook.com':
        return 'Facebook';
      case 'apple.com':
        return 'Apple';
      default:
        return 'Custom';
    }
  }

  async deleteAccount(): Promise<void> {
    this.spinner.show();
    const currentUser = this.auth.getCurrentFirebaseUser();
    try {
      await this.functions.deleteUserAccount(currentUser.uid, await currentUser.getIdToken());

      this.messages.setMessage('Profile.AccountDeleted');
      this.auth.signOut();
    }
    catch (error) {
      this.messages.setErrorMessage('Profile.AccountDeletionFailure', { apartmentId: error.error });
    }
    finally {
      this.spinner.hide();
    }
  }

  openDeleteAccountModal(template: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(template);
  }
}
