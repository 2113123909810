import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '../services/auth.service';
import { FirebaseFunctionsService } from '../services/firebase-functions.service';
import { MessagesService } from '../services/messages.service';

@Component({
  selector: 'app-app-instance-modal',
  templateUrl: './app-instance-modal.component.html',
  styleUrls: ['./app-instance-modal.component.scss']
})
export class AppInstanceModalComponent {

  appInstance: any;
  removeItemCallback: (appInstanceId: string, refreshToken: string) => void;
  @Input() apartmentId?: string = null;

  clicked = false;

  constructor(
    public bsModalRef: BsModalRef,
    private firebaseFunctions: FirebaseFunctionsService,
    private messageService: MessagesService,
    public auth: AuthService,
  ) { }

  public async revokeAppInstance() {
    this.bsModalRef.hide();
    try {
      const appInstanceId = this.appInstance.app_instance_id;
      const refreshToken = this.appInstance.refresh_token;

      await this.firebaseFunctions.revokeAppInstance(this.apartmentId, refreshToken);
      this.removeItemCallback(appInstanceId, refreshToken);
    } catch (e) {
      this.messageService.setMessage('AppInstanceManagement.RevokeFailure');
    }
  }
}
