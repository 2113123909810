import { Injectable } from '@angular/core';
import { EmailAuthProvider } from '@angular/fire/auth';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {
  constructor(
    private auth: AuthService,
  ) {
  }

  async getProviderIds(): Promise<string[]> {
    const currentUser = this.auth.getCurrentFirebaseUser();

    if (!currentUser) {
      return [];
    }

    const providerData = currentUser.providerData;
    const providers: string[] = [];
    for (const data of providerData) {
      const providerId = data.providerId;

      if (providerId !== EmailAuthProvider.PROVIDER_ID) {
        providers.push(providerId);
      }
    }

    return providers;
  }
}
