import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { PasswordMatchValidatorService } from '../services/password-match-validator.service';
import { MessagesService } from '../services/messages.service';
import { Router } from '@angular/router';
import { SpinnerService } from '../services/spinner.service';
import { ProviderService } from '../services/provider.service';
import { AuthService } from '../services/auth.service';

interface FormData {
    oldPassword: FormControl<string>;
    password: FormControl<string>;
    passwordConfirmation: FormControl<string>;
}

@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    public changePasswordForm: FormGroup<FormData>;

    constructor(
        private formBuilder: FormBuilder,
        private passwordMatch: PasswordMatchValidatorService,
        private messages: MessagesService,
        private router: Router,
        private authService: AuthService,
        private spinner: SpinnerService,
        private providerService: ProviderService
    ) { 
        this.buildChangePasswordForm();
    }

    ngOnInit(): void {
        this.providerService
            .getProviderIds()
            .then(providers => {
                if (providers.length > 0) {
                    this.router.navigateByUrl('/profile');
                    return;
                }
            });
    }

    public async changePasswordAction() {
        this.spinner.show();
        const currentUser = this.authService.getCurrentFirebaseUser();
        const email = currentUser.email;
        const oldPassword = this.changePasswordForm.value.oldPassword;
        const newPassword = this.changePasswordForm.value.password;

        if (oldPassword === newPassword) {
            this.messages.setMessage('ChangePassword.PasswordsEqual');
            this.spinner.hide();
            return;
        }

        try {
            await this.authService.confirmPassword(email, oldPassword);
            await this.authService.updatePassword(newPassword);

            this.messages.setMessage('ChangePassword.Completed');

            await this.authService.signOut();
        }
        catch (error) {
            if (error.code === 'auth/wrong-password') {
                this.messages.setErrorMessage('Auth.WrongPassword');
            }
            else if (error.code === 'auth/too-many-requests') {
                this.messages.setErrorMessage('Auth.TooManyRequests');
            }
            else {
                console.log(error);
                this.messages.setErrorMessage('Auth.SignInFailed');
            }
        }
        finally {
            this.spinner.hide();
        }
    }

    private buildChangePasswordForm() {
        this.changePasswordForm = this.formBuilder.group({
            oldPassword: ['', []],
            password: ['',
                [
                    Validators.pattern(/\d/),
                    Validators.pattern(/[a-z]/),
                    Validators.pattern(/[A-Z]/),
                    Validators.pattern(/[,.!"§$%&\/\\()=`´-]/),
                    Validators.minLength(6)
                ]
            ],
            passwordConfirmation: ['', [Validators.required]]
        }, {
            validators: this.passwordMatch.validator
        });
    }
}
