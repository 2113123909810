import { Component, OnInit } from '@angular/core';
import { FirebaseFunctionsService } from '../services/firebase-functions.service';
import { AppInstanceResponse } from '../models/appinstance/AppInstanceResponse';
import { AppInstance } from '../models/appinstance/AppInstance';
import { SpinnerService } from '../services/spinner.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-instance-management',
  templateUrl: './app-instance-management.component.html',
  styleUrls: ['./app-instance-management.component.scss']
})
export class AppInstanceManagementComponent implements OnInit {
  public availableAppInstances: AppInstance[] = [];
  apartmentId?: string = null;

  constructor(
    public auth: AuthService,
    private firebaseFunctions: FirebaseFunctionsService,
    private spinner: SpinnerService
  ) { }

  async ngOnInit() {
    this.loadUserAppInstances();
  }

  private async loadUserAppInstances(): Promise<void> {
    const availableAppInstances = this.availableAppInstances;

    if (availableAppInstances.length > 0) {
      return;
    }

    this.fetchUserAppInstances();
  }

  private async fetchUserAppInstances(): Promise<void> {
    this.spinner.show(true);
    try {
      const result = await Promise.all([
        this.firebaseFunctions.getAppInstances(),
        this.firebaseFunctions.getApartments(),
      ]);

      const appInstanceResponse = result[0];
      const apiResult = result[1];

      if (apiResult && apiResult.data && apiResult.data.attributes && apiResult.data.attributes.apartments) {
        this.apartmentId = apiResult.data.attributes.apartments[0].id;
      }

      this.availableAppInstances = this.mapAppInstancesFromApiResult(appInstanceResponse);
    }
    catch (error) {
      console.log(error);
    } finally {
      this.spinner.hide();
    }
  }

  private mapAppInstancesFromApiResult(apiResult: AppInstanceResponse): AppInstance[] {
    const result: AppInstance[] = [];

    apiResult.data.apps.forEach(app => {
      if (!app.app_name) {
        return;
      }

      const appInstance: AppInstance = {
        app_name: app.app_name,
        appinstances: app.app_instances
      };

      result.push(appInstance);
    });

    return result;
  }

  removeItem({ appinstanceId, refreshToken }): void {
    this.availableAppInstances = this
      .availableAppInstances
      .map((app) => ({
        ...app,
        appinstances: app.appinstances.filter((instance) => instance.app_instance_id !== appinstanceId || instance.refresh_token !== refreshToken)
      }))
      .filter((app) => app.appinstances.length);

    // alternative solution: call this.fetchUserAppInstances()
    // but this would cause the cards to collapse (or would require remembering which cards were expanded before update)
  }
}
