<div class="row justify-content-center mt-3">
    <div class="col-12 col-md-9 col-lg-8">
        <messages></messages>

        <div class="card my-3">
            <h4 class="card-header">{{'ResetPassword.Title'|translate}}</h4>
            <div class="card-body clearfix">
                <form [formGroup]="form" (submit)="resetPassword(this.form.value.email)">
                    <div class="form-group">
                        <p>{{'ResetPassword.Explanation'|translate}}</p>
                        <p>{{'ResetPassword.ReceivingNoEmail'|translate}}</p>
                        <label for="email">{{'ResetPassword.Email'|translate}}</label>
                        <input #emailControl class="form-control" id="email" type="email" formControlName="email" placeholder="{{'ResetPassword.EmailPlaceholder'|translate}}" required>
                        <control-messages [control]="form.get('email')"></control-messages>
                    </div>

                    <div class="mt-4">
                        <a class="btn btn-outline" id="dsResetPasswordComponentLinkCancel" [routerLink]="['/signin']">
                            {{'ResetPassword.Controls.Cancel'|translate}}
                        </a>
                        <button class="btn btn-primary float-end" id="dsResetPasswordComponentButtonSubmit" type="submit" [disabled]="!form.valid">
                            {{'ResetPassword.Controls.SubmitForm'|translate}}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
