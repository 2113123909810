<div class="row justify-content-center">
    <div class="col-12 col-md-9 col-lg-8">
    
        <messages></messages>

        <div *ngIf="mode === 'list'">
            <div class="d-flex align-items-center mb-2">
                <h2 class="me-auto">{{'ApartmentManagement.Title'|translate}}</h2>
            </div>
            <div class="row">
                <div class="col-12" *ngFor="let apartment of availableApartments; let i = index" [attr.data-index]="i">
                    <div class="card mb-3">
                        <apartment [apartment]="apartment"></apartment>
                    </div>
                </div>
            </div>
            <p *ngIf="!availableApartments.length">
                {{'ApartmentManagement.NoApartments'|translate}}
            </p>
        </div>

        <div *ngIf="mode === 'edit'">
            <h2 class="mb-3">{{'ApartmentManagement.Editor.Title'|translate}}</h2>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="card-text">
                                <form [formGroup]="editForm" (submit)="updateApartment()">
                                    <div class="form-group">
                                        <label for="name">{{'ApartmentManagement.Editor.Name' | translate }}</label>
                                        <input #apartmentNameControl class="form-control" id="name" type="text" formControlName="apartmentName" required>
                                        <control-messages [control]="editForm.get('apartmentName')"></control-messages>
                                    </div>

                                    <div class="mt-4 clearfix">
                                        <a class="btn btn-outline" id="dsApartmentManagementButtonEditAbort" [routerLink]="['/apartment-management']">
                                            <i class="fa fa-angle-double-left me-1"></i>
                                            {{'ApartmentManagement.Controls.AbortEdit'|translate}}
                                        </a>
                                        <button class="btn btn-primary float-end" id="dsApartmentManagementButtonEditSubmit" type="submit" [disabled]="!editForm.valid">
                                            {{'ApartmentManagement.Controls.SubmitForm'|translate}}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="mode === 'disconnect'">
            <h2 class="mb-3">{{'ApartmentManagement.Disconnect.Title'|translate}}</h2>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="card-text">
                                {{'ApartmentManagement.Disconnect.Text'|translate}}

                                <div class="mt-4 clearfix">
                                    <button class="btn btn-outline" id="dsApartmentManagementButtonDisconnectAbort" type="submit"
                                    (click)="router.navigate(['/apartment-management', { mode:'list', refresh:'true' }])">
                                        <i class="fa fa-angle-double-left me-1"></i>
                                        {{'ApartmentManagement.Controls.AbortDisconnect'|translate}}
                                    </button>

                                    <button class="btn btn-primary float-end" id="dsApartmentManagementButtonDisconnectSubmit" type="submit"
                                    (click)="executeDisconnectApartment()">
                                        {{'ApartmentManagement.Controls.SubmitDisconnect'|translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="mode === 'delete'">
            <h2 class="mb-3">
                <i class="text-danger fa fa-exclamation-triangle me-1"></i>
                {{'ApartmentManagement.Delete.Title'|translate}}
            </h2>
            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <div class="card-text">

                                <div class="mb-2 limited-text-width">
                                    {{'ApartmentManagement.Delete.Text'|translate}}
                                </div>

                                <div *ngIf="deletionWaitingForResult && !deletionDone" class="my-5 d-flex justify-content-center text-danger">
                                    <div class="spinner-border" role="status">
                                    </div>
                                </div>

                                <div *ngIf="!deletionWaitingForResult && deletionDone" class="alert alert-success" role="alert">
                                    {{'ApartmentManagement.Delete.Success'|translate}}
                                </div>

                                <div *ngIf="!deletionWaitingForResult && deletionFailed" class="alert alert-danger" role="alert">
                                    {{'ApartmentManagement.Delete.Failure'|translate}}
                                </div>


                                <div class="mt-4">
                                    <button class="btn btn-outline" id="dsApartmentManagementButtonDeleteAbort" type="submit"
                                    (click)="router.navigate(['/apartment-management', { mode:'list', refresh:'true' }])">
                                        <i class="fa fa-angle-double-left me-1"></i>
                                        {{'ApartmentManagement.Controls.AbortDelete'|translate}}
                                    </button>

                                    <button [disabled]="startedDeletionProcess" class="float-end btn btn-danger" id="dsApartmentManagementButtonDeleteSubmit" type="submit"
                                    (click)="executeDeleteApartment();">
                                        <i class="fa fa-trash-o me-1"></i>
                                        {{'ApartmentManagement.Controls.SubmitDelete'|translate}}
                                    </button>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
