<main class="main -0flex-shrink">

    <nav *ngIf="!hasRoute('signin-gateway')" class="navbar navbar-expand-lg navbar-light text-nowrap">
        <div class="container-fluid p-0">
            <a class="navbar-brand m-0" id="dsNavItemLinkHomeOne" [routerLink]="['/']" title="{{'App.Home' | translate}}">
                <img src="/assets/themes/ds-theme/images/my_ds_logo.png" alt="my.dS" />
            </a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent" >
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item dropdown">
                        <a href="javascript:void(0)" class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" [attr.language]="language" placement="left" tooltip="{{'App.SelectLanguage'|translate}}">
                            <i class="fa fa-globe"></i>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item nav-link" (click)="setLanguage('de_DE')" role="button">{{'App.SelectLanguage.DE' | translate}}</a></li>
                            <li><a class="dropdown-item nav-link" (click)="setLanguage('en_US')" role="button">{{'App.SelectLanguage.EN' | translate}}</a></li>
                            <li><a class="dropdown-item nav-link" (click)="setLanguage('nl_NL')" role="button">{{'App.SelectLanguage.NL' | translate}}</a></li>
                        </ul>
                    </li>
                    <li class="nav-item" *ngIf="!onboardingProcess && (auth.user$ | async)" [routerLinkActive]="['active']">
                        <a class="nav-link" id="dsNavItemLinkApartmentManagement" role="button" [routerLink]="['/apartment-management']" >
                            {{'App.ApartmentManagement' | translate}}
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="!onboardingProcess && (auth.user$ | async)" [routerLinkActive]="['active']">
                        <a class="nav-link" id="dsNavItemLinkAppInstanceManagement" role="button" [routerLink]="['/app-instance-management']" >
                            {{'AppInstanceManagement.Title' | translate}}
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="!onboardingProcess && (auth.user$ | async)" [routerLinkActive]="['active']">
                        <a class="nav-link" id="dsNavItemLinkEditProfile" [routerLink]="['/profile']" >
                            {{'App.EditProfile' | translate}}
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="!onboardingProcess && (auth.user$ | async)" [routerLinkActive]="['active']">
                        <a class="nav-link" id="dsNavItemLinkSignout" href="/" (click)="auth.signOut()">
                            {{'App.SignOut' | translate}}
                        </a>
                    </li>
                    <li class="nav-item separated">
                        <a class="nav-link" id="dsNavItemLinkDS" href="{{dsSiteLink}}" target="_blank">
                            {{'dS.Home' | translate}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <p *ngIf="isAuthorized && showWelcomeMessage" class="text-center py-2 mb-4 small bg-light">
        {{'App.Welcome' | translate}} {{fullName}}
        <span *ngIf="fullName">({{email}})</span>
        <span *ngIf="!fullName">{{email}}</span>
    </p>

    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12">
            <verification-pending-message
                *ngIf="isAuthorized"
                [isVerified]="isEmailVerified"
                [email]="email"></verification-pending-message>

            <account-delete-message *ngIf="deleteRequested"></account-delete-message>
            <router-outlet></router-outlet>
        </div>
      </div>
    </div>
</main>

<spinner></spinner>
