<div class="row justify-content-center">
    <div class="col-12 col-md-9 col-lg-8">
        <h2 class="mt-3">{{'SignUp.MainTitle'|translate}}</h2>

        <div class="card my-3">
            <h4 class="card-header">{{'Profile.FederatedSignupMessage'|translate}}</h4>
            <div class="card-body">
                <div class="d-flex">
                    <div class="d-grid col-12 col-md-5 mx-auto" >
                        <button id="dsSignupComponentButtonGoogleSignup" class="btn social-media google mt-1" (click)="openTermsModal(content, 'Google')">
                            <img src="/assets/sign-in-with-google/google_g_logo.svg" class="me-0" width="26px" height="26px"/> {{'Profile.Controls.SignUpWithGoogle'|translate}}  
                        </button>
                        <button id="dsSignupComponentButtonFacebookSignup" class="btn social-media facebook mt-1" (click)="openTermsModal(content, 'Facebook')">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16" id="facebook" width="26px" height="26px">
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                            </svg>
                            {{'Profile.Controls.SignUpWithFacebook'|translate}}
                        </button>
                        <button id="dsSignupComponentButtonAppleSignup" class="btn social-media apple mt-1" (click)="openTermsModal(content, 'Apple')">
                            <i class="fa fa-apple"></i> {{'Profile.Controls.SignUpWithAppleId'|translate}}
                        </button>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <p class="small mb-0">{{'Profile.FederatedPrivacyInformation'|translate}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="card my-3">
            <h4 class="card-header">{{'Profile.SignupMessage'|translate}}</h4>
            <div class="card-body">
                <messages></messages>

                <form [formGroup]="profileForm" (submit)="createAccount()">
                    <div class="form-group">
                        <label for="email">{{'Profile.Email'|translate}}</label>
                        <input #emailControl class="form-control" id="email" type="email" formControlName="email" required>
                        <control-messages [control]="profileForm.get('email')"></control-messages>
                    </div>
                    <div class="form-group">
                        <label for="password">{{'Profile.Password'|translate}}</label>
                        <input #passwordControl class="form-control" id="password" type="password" formControlName="password" required autocomplete="off">
                        <control-messages [control]="profileForm.get('password')"></control-messages>
                    </div>
                    <div class="form-group">
                        <label for="passwordConfirmation">{{'Profile.PasswordConfirmation'|translate}}</label>
                        <input #passwordConfirmationControl class="form-control" id="passwordConfirmation" type="password" formControlName="passwordConfirmation" required autocomplete="off">
                        <control-messages [control]="profileForm.get('passwordConfirmation')"></control-messages>
                    </div>
                    <div class="form-group form-check">
                        <input #acceptTermsAndConditionsControl id="acceptTermsAndConditions" type="checkbox" formControlName="acceptTermsAndConditions" value="true" required
                        [class]="'form-check-input' + (profileForm.value.acceptTermsAndConditions ? ' checked' : '')">
                        <label class="form-check-label" for="acceptTermsAndConditions">{{'Profile.AcceptTermsAndConditions'|translate}}</label>
                        <label class="form-check-label">&nbsp;</label>
                        <label class="form-check-label"><a [routerLink]="[]" (click)="openTermsAndConditions()">{{'Profile.TermsAndConditionsLink'|translate}}</a></label>
                        <control-messages [control]="profileForm.get('acceptTermsAndConditions')"></control-messages>
                    </div>
                    <div class="form-group form-check">
                        <input #acceptPrivacyPolicyControl id="acceptPrivacyPolicy" type="checkbox" formControlName="acceptPrivacyPolicy" value="true" required
                        [class]="'form-check-input' + (profileForm.value.acceptPrivacyPolicy ? ' checked' : '')">
                        <label class="form-check-label" for="acceptPrivacyPolicy">{{'Profile.AcceptPrivacyPolicy'|translate}}</label>
                        <label class="form-check-label">&nbsp;</label>
                        <label class="form-check-label"><a [routerLink]="[]" (click)="openPrivacyPolicy()">{{'Profile.PrivacyLink'|translate}}</a></label>
                        <control-messages [control]="profileForm.get('acceptPrivacyPolicy')"></control-messages>
                    </div>
                    <div class="mt-4 clearfix">
                        <a class="btn btn-outline" [routerLink]="[]" id="dsSignupComponentLinkCancelSignup" (click)="auth.signIn()">
                            {{'Profile.Controls.Cancel'|translate}}
                        </a>
                        <button class="btn btn-primary float-end" id="dsSignupComponentButtonCreateAccount" type="submit" [disabled]="!profileForm.valid">
                            {{'Profile.Controls.CreateAccount'|translate}}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'Profile.Controls.TermsAndConditionTitle'|translate}}</h4>
    </div>
    <div class="modal-body" [innerHTML]="'Profile.Controls.TermsAndConditionText'|translate">
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="cancelTermsModal()">
            {{'Profile.Controls.TermsAndConditionCancel'|translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="closeTermsModal()">
            {{'Profile.Controls.TermsAndConditionButton'|translate}}
        </button>
    </div>
</ng-template>
