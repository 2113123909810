import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompleteAuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router
  ){
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      const authenticatedUser = await firstValueFrom(this.auth.user$);
      if (authenticatedUser && (authenticatedUser.timestamp || authenticatedUser.lastModified) && authenticatedUser.lastName) {
        return true;
      }

      await this.router.navigate(['/profile/complete']);
      return false;
  }
}
