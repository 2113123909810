import { Component } from '@angular/core';

@Component({
  selector: 'not-verified',
  templateUrl: './not-verified.component.html',
  styleUrls: ['./not-verified.component.scss']
})
export class NotVerifiedComponent {

  constructor() {
  }
}
