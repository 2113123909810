import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FederatedAuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router
  ){
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      const federatedUser = this.auth.getCurrentFirebaseUser();
      if (!federatedUser) {
        console.log('not authenticated.');
        await this.router.navigate(['/signin']);
        return false;
      }

      const provider = federatedUser.providerData;
      if (!provider || provider.length !== 1) {
        console.log('federated, but invalid provider');
        await this.router.navigate(['/signin']);
        return false;
      }

      // if ('password'.localeCompare(provider[0].providerId) === 0) {
      //   console.log('federated, but password provider');
      //   this._router.navigate(['/signin']);
      //   return false;
      // }

      const authenticatedUser = await firstValueFrom(this.auth.user$);
      if (authenticatedUser) {
        console.log('federated, but also authenticated.');
        await this.router.navigate(['/profile']);
        return false;
      }

      console.log('allow federated.');
      return true;
  }
}
