import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AppInstance } from '../models/appinstance/AppInstance';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AppInstanceModalComponent } from '../app-instance-modal/app-instance-modal.component';

@Component({
  selector: 'app-instance-collapse',
  templateUrl: './app-instance-collapse.component.html',
  styleUrls: ['./app-instance-collapse.component.scss']
})
export class AppInstanceCollapseComponent {
  @Input() apartmentId?: string = null;
  @Input() appInstance: AppInstance;
  @Input() isCollapsed = false;
  @Input() id: string;
  @Output() removeItem = new EventEmitter();
  bsModalRef: BsModalRef;

  constructor(
    public router: Router,
    public modalService: BsModalService
  ) { }

  public toggleCollapseState = (): void => {
    this.isCollapsed = !this.isCollapsed;
  }

  public openRevokeAppInstanceModal(singleAppInstance: any) {
    this.bsModalRef = this.modalService.show(AppInstanceModalComponent);
    this.bsModalRef.content.appInstance = singleAppInstance;
    this.bsModalRef.content.apartmentId = this.apartmentId;
    this.bsModalRef.content.removeItemCallback = (appinstanceId, refreshToken) => {
      this.removeItem.emit({ appinstanceId, refreshToken });
    };
  }
}
