<account-delete-message *ngIf="deleteRequested"></account-delete-message>

<div class="row justify-content-center" *ngIf="profileForm && profileForm.value">
    <div class="col-12 col-md-9 col-lg-8">
        <messages></messages>
        <div class="card my-3">
            <h4 class="card-header">
                <span [translate]="'ProfileFederated.WelcomeMessage'" [translateParams]="{firstName:profileForm.value.firstName,lastName:profileForm.value.lastName}"></span>
            </h4>
            <div class="card-body">
                <form [formGroup]="profileForm" (submit)="updateAccount()">
                    <div class="form-group mb-4">
                        <div class="input-group">
                            <input #emailControl class="form-control" id="email" type="email" formControlName="email" required>
                            <div class="input-group-append" *ngFor="let provider of providerIds">
                                <span class="input-group-text" id="basic-addon2">{{provider}}</span>
                            </div>
                        </div>
                        <control-messages [control]="profileForm.get('email')"></control-messages>
                        <div>
                            <a class="btn btn-link btn-sm ms--4" id="dsProfileComponnentLinkDeleteAccount" (click)='openDeleteAccountModal(deleteAccountContent)'>{{'Profile.Controls.DeleteAccount'|translate}}</a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group form-check">
                                <input #signupNewsletterControl id="signupNewsletter" type="checkbox" formControlName="signupNewsletter"
                                [class]="'form-check-input' + (profileForm.value.signupNewsletter ? ' checked' : '')">
                                <label class="form-check-label" for="signupNewsletter">{{'ProfileFederated.SignupNewsletter'|translate}}</label>
                                <control-messages [control]="profileForm.get('signupNewsletter')"></control-messages>
                            </div>
                        </div>
                        <div class="form-group col-12 col-md-6">
                            <label for="languageSelect">{{'ProfileFederated.Language'|translate}} *</label>
                            <select #languageControl class="form-select" id="languageSelect" formControlName="language" required>
                                <option hidden>{{'ProfileFederated.LanguagePlaceholder'|translate}}</option>
                                <option *ngFor="let item of languages" [value]="item.uid">{{item.name}}</option>
                            </select>
                            <control-messages [control]="profileForm.get('language')"></control-messages>
                        </div>
                        <div class="form-group col-12 col-md-3 col-lg-2">
                            <label for="salutationSelect">{{'ProfileFederated.Salutation'|translate}} *</label>
                            <select #salutationControl class="form-select" id="salutationSelect" formControlName="salutation" required>
                                <option hidden></option>
                                <option *ngFor="let item of salutations" [value]="item.uid">{{item.name}}</option>
                            </select>
                            <control-messages [control]="profileForm.get('salutation')"></control-messages>
                        </div>
                        <div class="form-group col-12 col-md-4">
                            <label for="firstName">{{'ProfileFederated.FirstName'|translate}} *</label>
                            <input #firstNameControl class="form-control" id="firstName" type="text" formControlName="firstName" required>
                            <control-messages [control]="profileForm.get('firstName')"></control-messages>
                        </div>
                        <div class="form-group col-12 col-md-5 col-lg-6">
                            <label for="lastName">{{'ProfileFederated.LastName'|translate}} *</label>
                            <input #lastNameControl class="form-control" id="lastName" type="text" formControlName="lastName" required>
                            <control-messages [control]="profileForm.get('lastName')"></control-messages>
                        </div>
                        <div class="form-group col-12 col-md-9 col-lg-10">
                            <label for="street">{{'ProfileFederated.Residency.Street'|translate}} *</label>
                            <input #streetControl class="form-control" id="street" type="text" formControlName="street" required>
                            <control-messages [control]="profileForm.get('street')"></control-messages>
                        </div>
                        <div class="form-group col-12 col-md-3 col-lg-2">
                            <label for="number">{{'ProfileFederated.Residency.Number'|translate}} *</label>
                            <input #numberControl class="form-control" id="number" type="text" formControlName="number" required>
                            <control-messages [control]="profileForm.get('number')"></control-messages>
                        </div>
                        <div class="form-group col-12 col-md-4 col-lg-3">
                            <label for="zipCode">{{'ProfileFederated.Residency.ZipCode'|translate}} *</label>
                            <input #zipCodeControl class="form-control" id="zipCode" type="text" formControlName="zipCode" required>
                            <control-messages [control]="profileForm.get('zipCode')"></control-messages>
                        </div>
                        <div class="form-group col-12 col-md-8 col-lg-9">
                            <label for="city">{{'ProfileFederated.Residency.City'|translate}} *</label>
                            <input #cityControl class="form-control" id="city" type="text" formControlName="city" required>
                            <control-messages [control]="profileForm.get('city')"></control-messages>
                        </div>
                        <div class="form-group col-12 col-md-6">
                            <label for="countrySelect">{{'ProfileFederated.Residency.Country'|translate}} *</label>
                            <select #countryControl class="form-select" id="countrySelect" formControlName="country" required>
                                <option hidden>{{'ProfileFederated.Residency.CountryPlaceholder'|translate}}</option>
                                <option *ngFor="let item of countries" [value]="item.uid" [disabled]="!item.uid">
                                    {{item.name}}
                                </option>
                            </select>
                            <control-messages [control]="profileForm.get('country')"></control-messages>
                        </div>
                        <div class="form-group col-12 col-md-6">
                            <label for="phone">{{'ProfileFederated.Phone'|translate}}</label>
                            <input #phoneControl class="form-control" id="phone" type="text" formControlName="phone">
                            <control-messages [control]="profileForm.get('phone')"></control-messages>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12 col-md-3">
                            <small>* {{'ProfileFederated.MandatoryHint'|translate}}</small>
                        </div>
                        <div class="col-12 col-md-6">
                            <span class="badge badge-danger w-100 text-center" [hidden]="profileForm.valid">{{'ProfileFederated.MandatoryError'|translate}}</span>
                        </div>
                        <div class="col-12 col-md-3 text-end">
                            <button class="btn btn-primary" id="dsProfileFederatedComponentButtonSubmitProfile" type="submit" [disabled]="!profileForm.valid">
                                {{'ProfileFederated.Controls.SubmitForm'|translate}}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'Profile.Controls.TermsAndConditionTitle'|translate}}</h4>
    </div>
    <div class="modal-body" [innerHTML]="'Profile.Controls.TermsAndConditionText'|translate">
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="cancelTermsModal()">
            {{'Profile.Controls.TermsAndConditionCancel'|translate}}
        </button>
      <button type="button" id="dsProfileFederatedComponentButtonAcceptTerms" class="btn btn-primary" (click)="acceptTermsModal()">
          {{'Profile.Controls.TermsAndConditionButton'|translate}}
      </button>
    </div>
</ng-template>

<ng-template #deleteAccountContent let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title-deleteAccount">{{'Profile.DeleteAccountModal.Title'|translate}}</h4>
      <button type="button" id="dsProfileFederatedComponentButtonHideModalDeelteAccount" class="btn-vvvvvvvvvvvvvvvvclose" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>
            {{'Profile.DeleteAccountModal.Info'|translate}}
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" id="dsProfileComponentButtonChangeEmail" class="btn btn-primary" (click)="bsModalRef.hide()">
            {{'Profile.DeleteAccountModal.Cancel'|translate}}
        </button>
      <button type="button" id="dsProfileComponentButtonChangeEmail" class="btn btn-primary" (click)="deleteAccount(); bsModalRef.hide();">
          {{'Profile.DeleteAccountModal.Submit'|translate}}
      </button>
    </div>
</ng-template>