import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class EmailMatchValidatorService {

  constructor() { }

  public validator(control: AbstractControl): ValidationErrors {
    if (!control || !control.get('email') || !control.get('emailConfirmation')) {
      return;
    }

    const password: string = control.get('email').value;
    const confirmPassword: string = control.get('emailConfirmation').value;
    if (!confirmPassword || confirmPassword.length === 0) {
      return;
    }

    if (password !== confirmPassword) {
      control.get('emailConfirmation').setErrors({ emailMismatch: true });
    } else {
      control.get('emailConfirmation').setErrors(null);
    }
  }
}
