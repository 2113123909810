import { Injectable } from '@angular/core';
import { doc, docData, Firestore } from '@angular/fire/firestore';
import { User } from '../models/user.model';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor(private fireStore: Firestore) { }

    GetUserObservableDoc(uid: string): Observable<User> {
        const docRef = doc(this.fireStore, `users/${uid}`);
        return docData(docRef) as Observable<User>;
    }

    async GetUserDoc(uid: string): Promise<User> {
        return await firstValueFrom(this.GetUserObservableDoc(uid));
    }
}
