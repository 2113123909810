import { Component } from '@angular/core';

@Component({
  selector: 'account-delete-message',
  templateUrl: './account-delete-message.component.html',
})
export class AccountDeleteMessageComponent {
  constructor(
  ) {
  }
}
