<div class="row justify-content-center">
    <div class="col-12 col-md-9 col-lg-8 pt-3">
        <messages></messages>
        
        <div class="card my-3" *ngIf="unsupportedAction && !invalidRequest">
            <h4 class="card-header">{{'Verify.Unsupported.Title'|translate}}</h4>
            <div class="card-body">
                <p>
                    {{'Verify.Unsupported.UnsupportedAction'|translate}}
                </p>
            </div>
        </div>

        <div class="card my-3" *ngIf="!unsupportedAction && invalidRequest">
            <h4 class="card-header">{{'Verify.InvalidRequest.Title'|translate}}</h4>
            <div class="card-body">
                <p>
                    {{'Verify.InvalidRequest.InvalidRequest'|translate}}
                </p>
            </div>
        </div>

        <div class="card my-3" *ngIf="verifyEmail && !unsupportedAction && !invalidRequest">
            <h4 class="card-header">{{'Verify.Email.Title'|translate}}</h4>
            <div class="card-body">

                <form [formGroup]="verifyCodeForm" (submit)="verifyAction()">
                    <p>
                        {{'Verify.Email.Email'|translate}}
                    </p>
                    <div class="form-group">
                        <label for="email">{{'SignIn.Email'|translate}}</label>
                        <input #emailControl class="form-control" id="email" type="email" formControlName="email" placeholder="{{'SignIn.EmailPlaceholder'|translate}}" required>
                        <control-messages [control]="verifyCodeForm.get('email')"></control-messages>
                    </div>
                    <div class="form-group">
                        <label for="password">{{'SignIn.Password'|translate}}</label>
                        <input #passwordControl class="form-control" id="password" type="password" formControlName="password" placeholder="{{'SignIn.PasswordPlaceholder'|translate}}" required>
                        <control-messages [control]="verifyCodeForm.get('password')"></control-messages>
                    </div>

                    <div class="mt-4">
                        <button type="submit" class="btn btn-primary float-end" id="dsVerifyComponentButtonVerifyEmail" [disabled]="!verifyCodeForm.valid">{{'Verify.Email.Controls.SubmitForm'|translate}}</button>
                    </div>
                </form>
                
            </div>
        </div>

        <div class="card my-3" *ngIf="resetPassword && !unsupportedAction && !invalidRequest">
            <h4 class="card-header">{{'Verify.ResetPassword.Title'|translate}}</h4>
            <div class="card-body">
                <form [formGroup]="resetPasswordForm" (submit)="resetPasswordAction()">
                    <div class="form-group">
                        <label for="email">{{'Verify.ResetPassword.Email'|translate}}</label>
                        <input #emailControl class="form-control" id="email" type="email" formControlName="email" required>
                        <control-messages [control]="resetPasswordForm.get('email')"></control-messages>
                    </div>
                    <div class="form-group">
                        <label for="password">{{'Verify.ResetPassword.Password'|translate}}</label>
                        <input #passwordControl class="form-control" id="password" type="password" formControlName="password" required>
                        <control-messages [control]="resetPasswordForm.get('password')"></control-messages>
                    </div>
                    <div class="form-group">
                        <label for="passwordConfirmation">{{'Verify.ResetPassword.PasswordConfirmation'|translate}}</label>
                        <input #passwordConfirmationControl class="form-control" id="passwordConfirmation" type="password" formControlName="passwordConfirmation" required>
                        <control-messages [control]="resetPasswordForm.get('passwordConfirmation')"></control-messages>
                    </div>

                    <div class="mt-4">
                        <button 
                            class="btn btn-primary" 
                            id="dsVerifyComponentButtonResetPassword" 
                            [disabled]="!resetPasswordForm.valid"
                            (click)="resetPasswordAction()">
                            {{'Verify.ResetPassword.Controls.SubmitForm'|translate}}
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <div class="card my-3" *ngIf="recoverEmail && !unsupportedAction && !invalidRequest">
            <h4 class="card-header">{{'Verify.RecoverEmail.Title'|translate}}</h4>
            <div class="card-body">
                {{'Verify.RecoverEmail.ContactSupport'|translate}}
            </div>
        </div>
    </div>
</div>
