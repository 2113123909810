import { Injectable } from '@angular/core';
import { Country } from '../models/country.model';
import { firstValueFrom } from 'rxjs';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

const OPTION_SEPARATOR = { uid: '', name: '-------------', index: 4 } as Country;

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  items: Country[] = [];

  countryCodes: string[] = [
    'CH', 'DE', 'BE', 'NL',
    'AL', 'AR', 'AU', 'AT', 'BH', 'BY', 'BG', 'CN', 'CZ', 'DK', 'EG', 'FI', 'FR', 'GR',
    'HK', 'ID', 'IR', 'IL', 'IT', 'JO', 'LB', 'LU', 'MY', 'NZ', 'NO', 'PL', 'PT', 'RO', 'RU',
    'RS', 'SGB', 'ES', 'SE', 'TH', 'TR', 'UA', 'AE', 'GB', 'UY'
  ];

  constructor(
    private translate: TranslateService
  ) {
    this.countryCodes.forEach((element, index) => {
      const country = new Country();
      country.uid = element;
      if (index < 4) {
        country.index = index + 1;
      }

      this.items.push(country);
    });

    this.items.splice(4, 0, OPTION_SEPARATOR);

    this.prepareSortedItems();

    translate.onLangChange.subscribe((e: LangChangeEvent) => {
      this.prepareSortedItems();
    });
  }

  async prepareSortedItems() {
    const translate = this.translate;

    for (const cc of this.items) {
      if (cc.uid === '') {
        continue;
      }
      cc.name = await firstValueFrom(translate.get('Country.' + cc.uid));
    }

    const lang = translate.currentLang ? translate.currentLang.substring(0, 2) : 'en';


    this.items.sort((a, b) => {
      if (a.index > 0 && b.index > 0) {
        return a.index - b.index;
      }

      if (a.index > 0) {
        return -1;
      }

      if (b.index > 0) {
        return 1;
      }

      return a.name.localeCompare(b.name, lang);
    });
  }
}
