<div class="row justify-content-center">
    <div class="col-12 col-md-9 col-lg-8">
        <h2>
            {{'Profile.Title'|translate}}
        </h2>
        <messages></messages>
        <form [formGroup]="profileForm" (submit)="updateAccount()">
            <div class="card my-3">
                <h4 class="card-header">
                    <span [translate]="'Profile.WelcomeMessage'" [translateParams]="{firstName:profileForm.controls.firstName.value,lastName:profileForm.controls.lastName.value}"></span>
                </h4>
                <div class="card-body">
                    <div class="mb-4">
                        <div class="input-group">
                            <input #emailControl class="form-control" id="email" type="email" formControlName="email" required>
                            <div *ngFor="let provider of providerIds">
                                <span class="input-group-text" id="basic-addon2">{{provider}}</span>
                            </div>
                            <div *ngIf="!hasThirdPartyProvider" id="resetEmailContainer" class="ms-1">
                                <button class="btn btn-primary" id="dsProfileComponentButtonChangeEmail" (click)='openChangeEmail()'
                                [disabled]="!formEditable">
                                    <i class="fa fa-pencil-square-o"></i>
                                </button>
                            </div>
                        </div>
                        <control-messages [control]="profileForm.get('email')"></control-messages>
                        <div>
                            <a *ngIf="!hasThirdPartyProvider" class="btn btn-link btn-sm ms--4" id="dsProfileComponentLinkChangePassword" [routerLink]="['/change-password']">
                                {{'Profile.Controls.ChangePassword'|translate}}
                            </a>
                            <a class="btn btn-link btn-sm ms--4" id="dsProfileComponnentLinkDeleteAccount" (click)='openDeleteAccountModal(deleteAccountContent)'>{{'Profile.Controls.DeleteAccount'|translate}}</a>
                        </div>
                    </div>
                    <div class="row g-2">
                        <div class="col-12 col-md-6">
                            <div class="form-check">
                                <input #signupNewsletterControl id="signupNewsletter" type="checkbox" formControlName="signedUpNewsletter"
                                [class]="'form-check-input' + (profileForm.controls.signedUpNewsletter.value ? ' checked' : '')">
                                <label class="form-check-label" for="signupNewsletter">{{'Profile.SignupNewsletter'|translate}}</label>
                                <control-messages [control]="profileForm.get('signedUpNewsletter')"></control-messages>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <label for="languageSelect">{{'Profile.Language'|translate}}</label>
                            <select #languageControl class="form-select" id="languageSelect" formControlName="language" required>
                                <option hidden>{{'Profile.LanguagePlaceholder'|translate}}</option>
                                <option *ngFor="let item of languages" [value]="item.uid">{{item.name}}</option>
                            </select>
                            <control-messages [control]="profileForm.get('language')"></control-messages>
                        </div>
                        <div class="col-12 col-md-3 col-lg-2">
                            <label for="salutationSelect">{{'Profile.Salutation'|translate}}</label>
                            <select #salutationControl class="form-select" id="salutationSelect" formControlName="salutation" required>
                                <option *ngFor="let item of salutations" [value]="item.uid">{{item.name}}</option>
                            </select>
                            <control-messages [control]="profileForm.get('salutation')"></control-messages>
                        </div>
                        <div class="col-12 col-md-4">
                            <label for="firstName">{{'Profile.FirstName'|translate}}</label>
                            <input #firstNameControl class="form-control" id="firstName" type="text" formControlName="firstName" required>
                            <control-messages [control]="profileForm.get('firstName')"></control-messages>
                        </div>
                        <div class="col-12 col-md-5 col-lg-6">
                            <label for="lastName">{{'Profile.LastName'|translate}}</label>
                            <input #lastNameControl class="form-control" id="lastName" type="text" formControlName="lastName" required>
                            <control-messages [control]="profileForm.get('lastName')"></control-messages>
                        </div>
                        <div class="col-12 col-md-9 col-lg-10">
                            <label for="street">{{'Profile.Residency.Street'|translate}}</label>
                            <input #streetControl class="form-control" id="street" type="text" formControlName="street" required>
                            <control-messages [control]="profileForm.get('street')"></control-messages>
                        </div>
                        <div class="col-12 col-md-3 col-lg-2">
                            <label for="number">{{'Profile.Residency.Number'|translate}}</label>
                            <input #numberControl class="form-control" id="number" type="text" formControlName="number" required>
                            <control-messages [control]="profileForm.get('number')"></control-messages>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3">
                            <label for="zipCode">{{'Profile.Residency.ZipCode'|translate}}</label>
                            <input #zipCodeControl class="form-control" id="zipCode" type="text" formControlName="zipCode" required>
                            <control-messages [control]="profileForm.get('zipCode')"></control-messages>
                        </div>
                        <div class="col-12 col-md-8 col-lg-9">
                            <label for="city">{{'Profile.Residency.City'|translate}}</label>
                            <input #cityControl class="form-control" id="city" type="text" formControlName="city" required>
                            <control-messages [control]="profileForm.get('city')"></control-messages>
                        </div>
                        <div class="col-12 col-md-6">
                            <label for="countrySelect">{{'Profile.Residency.Country'|translate}}</label>
                            <select #countryControl class="form-select" id="countrySelect" formControlName="country" required>
                                <option hidden>{{'Profile.Residency.CountryPlaceholder'|translate}}</option>
                                <option *ngFor="let item of countries" [value]="item.uid" [disabled]="!item.uid">
                                    {{item.name}}
                                </option>
                            </select>
                            <control-messages [control]="profileForm.get('country')"></control-messages>
                        </div>
                        <div class="col-12 col-md-6">
                            <label for="phone">{{'Profile.Phone'|translate}}</label>
                            <input #phoneControl class="form-control" id="phone" type="text" formControlName="phone">
                            <control-messages [control]="profileForm.get('phone')"></control-messages>
                        </div>
                    </div>

                    <div class="d-flex justify-items-between mt-3">
                        <button class="btn btn-primary ms-auto" id="dsProfileComponentButtonEditProfile" (click)='enableFormEdition()'
                        *ngIf="!formEditable">
                            {{'App.EditProfile'|translate}}
                        </button>

                        <button class="btn btn-outline" id="dsProfileComponentButtonCancelProfileEdition" (click)='cancelFormEdition()'
                        *ngIf="formEditable">
                            {{'Profile.Controls.Cancel'|translate}}
                        </button>
                        <button class="btn btn-primary ms-auto" id="dsProfileComponentButtonSubmitProfile" type="submit"
                        *ngIf="formEditable"
                        [disabled]="!profileForm.valid">
                            {{'Profile.Controls.SubmitForm'|translate}}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<ng-template #deleteAccountContent let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title-deleteAccount">{{'Profile.DeleteAccountModal.Title'|translate}}</h4>
      <button type="button" id="dsProfileFederatedComponentButtonHideModalDeelteAccount" class="btn-close" aria-label="Close" (click)="bsModalRef.hide()">
      </button>
    </div>
    <div class="modal-body">
        <p>
            {{'Profile.DeleteAccountModal.Info'|translate}}
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" id="dsProfileComponentButtonChangeEmail" class="btn btn-primary" (click)="bsModalRef.hide()">
            {{'Profile.DeleteAccountModal.Cancel'|translate}}
        </button>
      <button type="button" id="dsProfileComponentButtonChangeEmail" class="btn btn-primary" (click)="deleteAccount(); bsModalRef.hide();">
          {{'Profile.DeleteAccountModal.Submit'|translate}}
      </button>
    </div>
</ng-template>