import { Component, OnInit, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MessagesService } from '../services/messages.service';
import { SpinnerService } from '../services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'verification-pending-message',
  templateUrl: './verification-pending-message.component.html',
  styleUrls: ['./verification-pending-message.component.scss']
})
export class VerificationPendingMessageComponent implements OnInit {
  @Input() email = 'your email';
  @Input() isVerified = true;
  clickHereToChangeEmailMessage: { first: string, link: string, last: string } = { first: '', link: '', last: '' };
  clickToSendVerificationEmailMessage: { first: string, link: string, last: string } = { first: '', link: '', last: '' };

  constructor(
    private fireauth: AngularFireAuth,
    private messageService: MessagesService,
    private spinner: SpinnerService,
    private translate: TranslateService,
    private router: Router
  ) {
  }

  async ngOnInit() {
    this.translate.get('Messages.Profile.ClickHereToChangeEmail')
      .subscribe(result => { this.splitMessage(result, this.clickHereToChangeEmailMessage); });
    this.translate.get('Messages.Profile.ClickToSendVerificationEmail')
      .subscribe(result => { this.splitMessage(result, this.clickToSendVerificationEmailMessage); });
  }

  changeEmail(): void {
    this.router.navigate(['/change-email']);
  }

  async sendEmailVerification(): Promise<void> {
    this.spinner.show(true);
    try {
      this.fireauth.languageCode = new Promise<string>(() => this.translate.currentLang);

      const u = await this.fireauth.currentUser;
      await u.sendEmailVerification();
      this.messageService.pushMessage('Profile.VerificationEmailSent');
    }
    catch (error) {
      if (error.code === 'auth/too-many-requests') {
        this.messageService.pushErrorMessage('Profile.EmailAlreadySent');
      } else {
        console.log('sendEmail');
        console.log(error);
      }
    }
    finally {
      this.spinner.hide();
    }
  }

  splitMessage(text: string, result: { first: string, link: string, last: string }) {
    let parts = text.split('<a>');
    result.first = parts[0];
    parts = parts[1].split('</a>');
    result.link = parts[0];
    result.last = parts[1];
  }
}
