<div class="row justify-content-center">
    <div class="col-12 col-md-9 col-lg-8">
        <div class="alert alert-warning alert-dismissible" role="alert">
            <div>
                {{'Messages.Browser.CookieDisabled' | translate}}
            </div>
            <div>
                {{ helpMessage.first }}
                <a [routerLink]="[]" id="dsVerificationPendingLinkChangeEmail" (click)="helpCenter()">{{ helpMessage.link }}</a>
                {{ helpMessage.last }}
            </div>
        </div>
        <hr class="subtle mt-4"/>
    </div>
</div>
