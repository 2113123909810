import { Component, OnInit, OnDestroy } from '@angular/core';
import { SpinnerService } from '../services/spinner.service';
import { FirebaseFunctionsService } from '../services/firebase-functions.service';
import { MessagesService } from '../services/messages.service';
import { Subscription } from 'rxjs';
import { ApartmentResponse } from '../models/apartment/ApartmentResponse';
import { Apartment } from '../models/apartment/Apartment';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../services/auth.service';

interface FromData {
  apartmentName: FormControl<string>;
}

@Component({
  selector: 'apartment-management',
  templateUrl: './apartment-management.component.html',
  styleUrls: ['./apartment-management.component.scss']
})
export class ApartmentManagementComponent implements OnInit, OnDestroy {

  public availableApartments: Apartment[] = [];
  public mode = 'list';
  public editForm: FormGroup<FromData>;

  routeParamsHandler: Subscription;
  selectedApartment: Apartment | null = null;
  navigationParameters: Params | null = null;

  deletionFailed = false;
  deletionDone = false;
  deletionWaitingForResult = false;
  startedDeletionProcess = false;

  constructor(
    public router: Router,
    private functions: FirebaseFunctionsService,
    private messages: MessagesService,
    private spinner: SpinnerService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.buildForm();

    this.router.events.subscribe((val) => {
      this.deletionDone = false;
      this.deletionWaitingForResult = false;
      this.deletionFailed = false;
      this.startedDeletionProcess = false;
    });
  }

  ngOnInit() {
    this.routeParamsHandler = this.route.params.subscribe(e => this.handleNavigationParams(e));
  }

  ngOnDestroy() {
    this.routeParamsHandler.unsubscribe();
  }

  private handleNavigationParams(e: Params) {
    this.navigationParameters = e;

    switch (e.mode) {
      case 'edit':
        this.editApartment(e.apartment);
        break;
      case 'delete':
        this.deleteApartment(e.apartment);
        break;
      case 'disconnect':
        this.disconnectApartment(e.apartment);
        break;
      default:
        this.listApartments();
    }
  }

  private buildForm(): void {
    this.editForm = this.formBuilder.group({
      apartmentName: ['', [Validators.required]]
    });
  }

  private getSortableValue(feed: string): number {
    // order results by feed:
    // 1. production
    // 2. beta
    // 3. alpha
    // 4. development

    switch (feed) {
      case 'development':
        return 4;
      case 'alpha':
        return 3;
      case 'beta':
        return 2;
      case 'production':
        return 1;
    }

    return 99;
  }

  private sortApartments(a: Apartment, b: Apartment): number {
    const feedSort = this.getSortableValue(a.feed) - this.getSortableValue(b.feed);
    if (feedSort === 0) {
      return a.name.localeCompare(b.name);
    }

    return feedSort;
  }

  private async mapApartmentsFromApiResult(apiResult: ApartmentResponse): Promise<Apartment[]> {
    const result: Apartment[] = [];

    if (apiResult && apiResult.data && apiResult.data.attributes && apiResult.data.attributes.apartments) {
      apiResult.data.attributes.apartments.forEach(async apartmentResult => {
        console.log(apartmentResult);
        // let backupMetadata = await this._functions.getBackups(apartmentResult.attributes.dssId, apartmentResult.attributes.feed);
        const apartment: Apartment = {
          id: apartmentResult.id,
          dssId: apartmentResult.attributes.dssId,
          feed: apartmentResult.attributes.feed,
          name: apartmentResult.attributes.name,
          pagekiteLink: apartmentResult.attributes.pagekiteLink,
          pagekitePassword: apartmentResult.attributes.pagekitePassword,
          ospToken: apartmentResult.attributes.ospToken,
          macAddress: apartmentResult.attributes.macAddress,
          inDeletionProcess: apartmentResult.attributes.inDeletionProcess,
          inDisconnectProcess: apartmentResult.attributes.inDisconnectProcess,
          createdAt: apartmentResult.attributes.createdAt
          // backups: await this.mapBackupsFromApiResult(backupMetadata)
        };

        result.push(apartment);
      });
    }

    const sortedResult = result.sort((a, b) => this.sortApartments(a, b));

    return sortedResult;
  }

  private async loadUserApartments(): Promise<Apartment[]> {
    if (this.availableApartments.length > 0 && !this.navigationParameters.refresh) {
      return this.availableApartments;
    }

    this.spinner.show(false);

    try {
      this.availableApartments = await this.mapApartmentsFromApiResult(await this.functions.getApartments());
      return this.availableApartments;
    }
    catch (error) {
      this.messages.pushErrorMessage('ApartmentManagement.Error.LoadApartmentsFailed');
      console.log(error);
    }
    finally {
      this.spinner.hide();
    }
  }

  private async editApartment(apartmentId: string): Promise<void> {
    this.mode = 'edit';
    const apartment = await this.getApartment(apartmentId);

    if (!apartment) {
      this.setDefaultMode();
      return;
    }

    this.editForm.setValue({ apartmentName: apartment.name });
    this.selectedApartment = apartment;
  }

  public async updateApartment(): Promise<void> {
    if (!this.editForm.valid) {
      return;
    }

    try {
      this.spinner.show();
      const newApartmentName = this.editForm.value.apartmentName;

      let disconnectProcess = this.selectedApartment.inDisconnectProcess;
      if (!disconnectProcess) {
        disconnectProcess = false;
      }
      let deleteProcess = this.selectedApartment.inDeletionProcess;
      if (!deleteProcess) {
        deleteProcess = false;
      }

      const body = {
        data: {
          type: 'apartment',
          attributes: {
            feed: this.selectedApartment.feed,
            dssId: this.selectedApartment.dssId,
            name: newApartmentName,
            macAddress: this.selectedApartment.macAddress,
            inDeletionProcess: deleteProcess,
            inDisconnectProcess: disconnectProcess
          }
        }
      };

      await this.functions.updateApartment(this.selectedApartment.id, body);
      this.selectedApartment.name = newApartmentName;
      this.messages.pushMessage('ApartmentManagement.UpdateSucceeded');
      this.router.navigate(['/apartment-management']);
    }
    catch (error) {
      this.messages.pushErrorMessage('ApartmentManagement.Error.UpdateFailed');
      console.log(error);
    }
    finally {
      this.spinner.hide();
    }
  }

  private async disconnectApartment(apartmentId: string): Promise<void> {
    this.mode = 'disconnect';
    const apartment = await this.getApartment(apartmentId);

    if (!apartment) {
      this.setDefaultMode();
      return;
    }

    this.selectedApartment = apartment;
  }

  public async executeDisconnectApartment(): Promise<void> {
    try {

      this.messages.clear();
      this.spinner.show();

      await this.functions.deleteDssConnection(this.selectedApartment.dssId, this.selectedApartment.feed);
      if (this.selectedApartment.inDisconnectProcess) {
        this.messages.pushWarningMessage('ApartmentManagement.DisconnectInProcess');
      }
      else {
        this.messages.pushMessage('ApartmentManagement.DisconnectSucceeded');
      }
      this.router.navigate(['/apartment-management', { refresh: true }]);
    }
    catch (error) {
      this.messages.pushErrorMessage('ApartmentManagement.Error.DisconnectFailed');
      console.log(error);
    }
    finally {
      this.spinner.hide();
    }
  }

  private async deleteApartment(apartmentId: string): Promise<void> {
    this.mode = 'delete';
    const apartment = await this.getApartment(apartmentId);

    if (!apartment) {
      this.setDefaultMode();
      return;
    }

    this.selectedApartment = apartment;
  }

  public async executeDeleteApartment(): Promise<void> {

    this.messages.clear();
    this.deletionWaitingForResult = true;
    this.startedDeletionProcess = true;

    // Set inDeletionProcess flag in apartment
    try {
      const body = {
        data: {
          type: 'apartment',
          attributes: {
            feed: this.selectedApartment.feed,
            dssId: this.selectedApartment.dssId,
            name: this.selectedApartment.name,
            macAddress: this.selectedApartment.macAddress,
            inDeletionProcess: true,
            inDisconnectProcess: true
          }
        }
      };

      await this.functions.updateApartment(this.selectedApartment.id, body);

      try {

        const idToken = await this.authService.getIdToken();
        await this.functions.deleteApartment(this.selectedApartment.id, idToken);
        this.deletionWaitingForResult = false;
        this.deletionDone = true;
      }
      catch (error) {
        this.deletionFailed = true;
        this.deletionWaitingForResult = false;
        console.log(error);
      }

    }
    catch (error) {
      this.deletionFailed = true;
      this.deletionWaitingForResult = false;
      console.log(error);
    }

  }

  private async getApartment(apartmentId: string): Promise<Apartment | null> {
    const apartments = await this.loadUserApartments();
    let apartment: Apartment = null;
    apartments.forEach(a => {
      if (a.id === apartmentId) {
        apartment = a;
      }
    });

    return apartment;
  }

  private listApartments() {
    this.mode = 'list';
    this.selectedApartment = null;
    this.loadUserApartments();
  }

  private setDefaultMode() {
    this.messages.pushMessage('ApartmentManagement.Error.ApartmentNotFound');
    this.selectedApartment = null;
    this.listApartments();
  }
}
