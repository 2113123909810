<div>
    <div class="card my-3">
        <div class="card-body">
            <h2 class="card-title">{{'Deprecated.Title'|translate}}</h2>
            <div class="card-text limited-text-width">
                {{'Deprecated.Text'|translate}}
            </div>
        </div>
    </div>
</div>
