import { Component, OnInit, TemplateRef } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '../models/user.model';
import { MessagesService } from '../services/messages.service';
import { Router } from '@angular/router';
import { PasswordMatchValidatorService } from '../services/password-match-validator.service';
import { SpinnerService } from '../services/spinner.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ParameterService } from '../services/parameter.service';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseFunctionsService } from '../services/firebase-functions.service';

interface FormData {
  email: FormControl<string>;
  password: FormControl<string>;
  passwordConfirmation: FormControl<string>;
  acceptTermsAndConditions: FormControl<boolean>;
  acceptPrivacyPolicy: FormControl<boolean>;
}

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  profileForm: FormGroup<FormData>;
  bsModalRef: BsModalRef;
  target: string;

  constructor(
    public auth: AuthService,
    public params: ParameterService,
    private formBuilder: FormBuilder,
    private messageService: MessagesService,
    private router: Router,
    private passwordMatch: PasswordMatchValidatorService,
    private spinner: SpinnerService,
    private modalService: BsModalService,
    private translate: TranslateService,
    private functions: FirebaseFunctionsService
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this.messageService.clear();

    this.auth.getRedirectResult()
      .then(result => {
        if (!result || !result.user) {
          return;
        }

        if (this.params.isRedirectAuthFlow()) {
          this.router.navigateByUrl('/profile/federated' + this.params.getRequestQueryParametersString());
        } else {
          this.router.navigate(['/profile/federated']);
        }
      })
      .catch(error => {
        switch (error.code) {
          case 'auth/account-exists-with-different-credential':
            this.messageService.pushErrorMessage('ProfileFederated.AccountExistsDifferentCredentials');
            break;
          default:
            console.log(error);
            this.messageService.pushErrorMessage('ProfileFederated.UnknownError');
        }
      });
  }

  buildForm(): void {
    this.profileForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['',
        [
          Validators.pattern(/\d/),
          Validators.pattern(/[a-z]/),
          Validators.pattern(/[A-Z]/),
          Validators.pattern(/[,.!"§$%&\/\\()=`´-]/),
          Validators.minLength(6)
        ]
      ],
      passwordConfirmation: ['', [Validators.required]],
      acceptTermsAndConditions: [false, [Validators.requiredTrue]],
      acceptPrivacyPolicy: [false, [Validators.requiredTrue]]
    }, {
      validators: this.passwordMatch.validator
    });
  }

  createAccount(): void {
    if (this.profileForm.invalid) {
      return;
    }

    this.spinner.show(true);

    if (this.translate.currentLang && this.translate.currentLang.length > 0) {
      this.auth.languageCode = this.translate.currentLang;
    }
    localStorage.setItem('termsAccepted', 'true');

    this.auth.createUserWithEmailAndPassword(this.profileForm.value.email, this.profileForm.value.password)
      .then(() => {
        this.updateAccount();
        this.sendEmailVerification();
        this.router.navigate(['/profile/federated']);
      })
      .catch(error => {
        if (error.code === 'auth/email-already-in-use') {
          this.messageService.setMessage('Signup.EmailInUse_WithResetLink');
        }
        else {
          this.messageService.setMessage('Signup.SignupFailed');
          console.log(error);
        }
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  async updateAccount(): Promise<void> {
    const currentUser = this.auth.getCurrentFirebaseUser();

    const account: User = {
      uid: currentUser.uid,
      email: currentUser.email,
      signedUpNewsletter: true,
      acceptedTermsAndConditions: true,
      acceptedPrivacyPolicy: true,
      accountType: this.target ? this.target : 'Custom',
      confirmed: false,
      verifySendAt: new Date()
    };
    await this
      .functions
      .createUserAccount(account)
      .catch(error => {
        // TODO: Show info to user.
        console.log(error);
      });
  }

  async sendEmailVerification(): Promise<void> {
    if (this.translate.currentLang && this.translate.currentLang.length > 0) {
      this.auth.languageCode = this.translate.currentLang;
    }

    const currentUser = this.auth.getCurrentFirebaseUser();
    await this.auth.sendEmailVerification(currentUser);
    this.messageService.pushMessage('Profile.VerificationEmailSent');
  }

  openTermsModal(template: TemplateRef<any>, target: string) {
    this.bsModalRef = this.modalService.show(template);
    this.target = target;
  }

  cancelTermsModal() {
    this.bsModalRef.hide();
  }

  closeTermsModal() {
    if (this.target === 'Google') {
      this.auth.signInWithGoogleWithRedirect(true);
    } else if (this.target === 'Facebook') {
      this.auth.signInWithFacebookWithRedirect(true);
    } else if (this.target === 'Apple') {
      this.auth.signInWithAppleIdWithRedirect(true);
    }
  }

  openTermsAndConditions() {
    if (this.translate.currentLang === 'en_US') {
      window.open('https://www.digitalstrom.com/en/terms-use/', '_blank').focus();
    } else if (this.translate.currentLang === 'nl_NL') {
      window.open('https://www.digitalstrom.com/nl/gebruiksvoorwaarden/', '_blank').focus();
    } else if (this.translate.currentLang === 'fr_FR') {
      window.open('https://www.digitalstrom.com/fr/conditions-dutilisation/', '_blank').focus();
    } else {
      window.open('https://www.digitalstrom.com/nutzungsbedingungen/', '_blank').focus();
    }
  }

  openPrivacyPolicy() {
    if (this.translate.currentLang === 'en_US') {
      window.open('https://www.digitalstrom.com/en/privacy/', '_blank').focus();
    } else if (this.translate.currentLang === 'nl_NL') {
      window.open('https://www.digitalstrom.com/nl/opmerkingen-en-bepalingen-inzake-gegevensbescherming/', '_blank').focus();
    } else if (this.translate.currentLang === 'fr_FR') {
      window.open('https://www.digitalstrom.com/fr/protection-des-donnees/', '_blank').focus();
    } else {
      window.open('https://www.digitalstrom.com/datenschutz/', '_blank').focus();
    }
  }
}
