import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'control-messages',
  templateUrl: './control-messages.component.html',
  styleUrls: ['./control-messages.component.scss']
})
export class ControlMessagesComponent {

  @Input() control: UntypedFormControl;

  constructor(private translate: TranslateService) { }

  get errorMessage(): Observable<any>{
    for (const errorProperty in this.control.errors) {
      if (this.control.errors.hasOwnProperty(errorProperty) && (this.control.touched || this.control.dirty)) {
        return this.translate.get('FormErrors.' + errorProperty, this.control.errors[errorProperty]).pipe(
            map((translated) => (errorProperty === 'pattern' ? translated + ' ,.!"§$%&\\/\()=`´-' : translated))
        );
      }
    }

    return null;
  }
}
