import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessagesService } from '../services/messages.service';
import { ParameterService } from '../services/parameter.service';

interface FormData {
  email: FormControl<string>;
  password: FormControl<string>;
}

@Component({
  selector: 'signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  form: FormGroup<FormData>;
  feeds = ['Development', 'Alpha', 'Production'];
  feedSelect = 'Select a feed';
  specialAccount = false;
  feed: string;
  customGreetings = '';

  constructor(
    public auth: AuthService,
    public params: ParameterService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private messages: MessagesService,
    private translate: TranslateService
  ) {
    this.buildForm();
    this.feed = this.route.snapshot.queryParams.feed;
  }

  ngOnInit() {
    this.auth.getRedirectResult()
      .then(result => {
        if (!result || !result.user) {
          return;
        }

        if (this.params.isRedirectAuthFlow()) {
          this.router.navigateByUrl('/profile/federated' + this.params.getRequestQueryParametersString());
        } else {
          this.router.navigate(['/profile/federated']);
        }
      })
      .catch(error => {
        switch (error.code) {
          case 'auth/account-exists-with-different-credential':
            this.messages.pushErrorMessage('ProfileFederated.AccountExistsDifferentCredentials');
            break;
          default:
            console.log(error);
            this.messages.pushErrorMessage('ProfileFederated.UnknownError');
        }
      });

    if (this.params.contains('greeting')) {
      this.customGreetings = this.params.get('greeting').value;
    }
  }

  async signIn(username: string, password: string): Promise<void> {
    try {
      await this.auth.defaultSignInWithEmailAndPasswordFlow(username, password);
    } catch
    {
      // catch unhandled exception
    }
  }

  buildForm() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  removeParam(key: string, sourceURL: string) {
    let rtn = sourceURL.split('?')[0];
    const queryString = (sourceURL.indexOf('?') !== -1) ? sourceURL.split('?')[1] : '';
    if (queryString !== '') {
      const paramsArray = queryString.split('&');
      for (let i = paramsArray.length - 1; i >= 0; i -= 1) {
        const param = paramsArray[i].split('=')[0];
        if (param === key) {
          paramsArray.splice(i, 1);
        }
      }
      rtn = rtn + '?' + paramsArray.join('&');
    }
    return rtn;
  }

  setFeed() {

    // In case we have a client_id query in our url, we can assume that some oAuth-Flow is in process -> use /authorize uri
    // Else use default uri for simple login (if we don't do this the /authorize endpoint will show a json formatted error)

    let currentUri = document.location.href;
    currentUri = decodeURIComponent(currentUri);

    // remove and app_instance_id, app_instance_name, app_name to avoid duplicates
    currentUri = this.removeParam('appinstance_id', currentUri);
    // currentUri = this.removeParam('appinstance_name', currentUri);
    currentUri = this.removeParam('app_name', currentUri);

    const splittedUri = currentUri.split('?', 2);
    let queryString = '?' + splittedUri[1];

    if (queryString.includes('&feed=Development')) {
      queryString = queryString.replace('&feed=Development', '');
    }
    if (queryString.includes('&feed=Alpha')) {
      queryString = queryString.replace('&feed=Alpha', '');
    }
    if (queryString.includes('&feed=Beta')) {
      queryString = queryString.replace('&feed=Beta', '');
    }

    const useDefaultUrl = !queryString.includes('client_id');

    switch (this.feedSelect) {

      case 'Development':
        if (useDefaultUrl) {
          document.location.href = 'https://dev-accounts.digitalstrom.com/signin';
        }
        else {
          const finalUri = 'https://dsdev.devapi.digitalstrom.com/v2/accessmanagement/authorize' + queryString + '&feed=Development';
          document.location.href = finalUri;
        }
        break;
      case 'Alpha':
        if (useDefaultUrl) {
          document.location.href = 'https://staging-accounts.digitalstrom.com/signin';
        }
        else {
          const finalUri = 'https://dsalpha.devapi.digitalstrom.com/v2/accessmanagement/authorize' + queryString + '&feed=Alpha';
          document.location.href = finalUri;
        }
        break;
      case 'Production':
        if (useDefaultUrl) {
          document.location.href = 'https://accounts.digitalstrom.com/signin';
        }
        else {
          const finalUri = 'https://ds.api.digitalstrom.com/v2/accessmanagement/authorize' + queryString;
          document.location.href = finalUri;

        }
        break;

      default:
        break;
    }
  }

  setSpecialAccount() {
    this.specialAccount = !this.specialAccount;
  }

  get specialAccountInfo() {
    const [first, link, last] = this.translate.instant('SignIn.SpecialAccountInfo').split(/\<a\>|\<\/a\>/);
    return { first, link, last };
  }

}
