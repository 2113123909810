<messages></messages>

<div *ngIf="mode === 'parameter-error'">
    <div class="card my-3">
        <div class="card-body">
            <h2 class="card-title">{{'RemoteConnectivity.Error.ParameterErrorTitle'|translate}}</h2>
            <p class="card-text">{{'RemoteConnectivity.Error.ParameterErrorText'|translate}}</p>
        </div>
    </div>
</div>

<div *ngIf="mode === 'wizard'">
    <div *ngIf="connectionType === attemptedConnectionType.Blocked" class="card my-3">
        <div class="card-body">
            <h2 class="card-title">{{'RemoteConnectivity.Wizard.Blocked.Title'|translate}}</h2>
            <p class="card-text">{{'RemoteConnectivity.Wizard.Blocked.Text'|translate}}</p>
            <p class="card-text">{{'RemoteConnectivity.Wizard.Mac'|translate}} {{mac}}</p>
            <p class="card-text">{{'RemoteConnectivity.Wizard.dSId'|translate}} {{dssId}}</p>
        </div>
    </div>

    <div *ngIf="connectionType === attemptedConnectionType.Reconnect" class="card my-3">
        <div class="card-body clearfix">
            <h2 class="card-title">{{'RemoteConnectivity.Wizard.Reconnect.Title'|translate}}</h2>
            <div class="card-text">
                {{'RemoteConnectivity.Wizard.Reconnect.Text'|translate}}
                <div class="list-group mb-4 mt-4">
                    <button
                        *ngFor="let apartment of availableApartments; let i = index"
                        (click)="selectedApartment = apartment.id"
                        type="button"
                        class="list-group-item list-group-item-action"
                        [class.list-group-item-success]="apartment.id === selectedApartment"
                        [attr.data-index]="i"
                        id="dsRemoteConnectivityComponentButtonReconnectSelectExisting"
                    >
                    <ng-container [ngTemplateOutlet]="feed" [ngTemplateOutletContext]="{feed:apartment.feed, name:apartment.name}"></ng-container>
                    </button>
                    <!-- <button id="dsRemoteConnectivityComponentButtonReconnectCreateNew" (click)="selectedApartment = 'new'" type="button" class="list-group-item list-group-item-action" [class.list-group-item-success]="selectedApartment == 'new'">{{ 'RemoteConnectivity.Wizard.Reconnect.OptionCreateNew' | translate }}</button> -->
                </div>
            </div>

            <button [disabled]="selectedApartment === null" id="dsRemoteConnectivityComponentButtonReconnectSubmit" (click)="createApartmentProcess()" type="button" class="btn btn-primary float-end">
                {{'RemoteConnectivity.Wizard.Reconnect.Controls.SubmitForm'|translate}}
            </button>
        </div>
    </div>

    <div *ngIf="connectionType === attemptedConnectionType.Connect" class="card my-3">
        <div class="card-body">
            <h2 class="card-title">{{'RemoteConnectivity.Wizard.Connect.Title'|translate}}</h2>
            <div class="card-text">
                {{'RemoteConnectivity.Wizard.Connect.Text'|translate}}
                <div class="list-group mb-4 mt-4">
                    <button (click)="selectedApartment = apartment.id; apartmentChosen = true"
                    type="button"
                    *ngFor="let apartment of availableApartments; let i = index"
                    [attr.data-index]="i"
                    class="list-group-item list-group-item-action"
                    [class.list-group-item-success]="apartment.id === selectedApartment"
                    id="dsRemoteConnectivityComponentButtonConnectSelectExisting"
                    >
                        <ng-container [ngTemplateOutlet]="feed" [ngTemplateOutletContext]="{feed:apartment.feed, name:apartment.name}"></ng-container>
                    </button>

                    <div *ngIf="(availableApartments?.length < 1)">
                        <button id="dsRemoteConnectivityComponentButtonConnectCreateNew" type="button" class="list-group-item list-group-item-action"
                        [class.list-group-item-success]="selectedApartment === 'new'" (click)="selectedApartment = 'new'; apartmentChosen = true">
                            {{'RemoteConnectivity.Wizard.Connect.OptionCreateNew'|translate}}
                        </button>
                    </div>
                </div>
            </div>

            <button id="dsRemoteConnectivityComponentButtonConnectSubmit" (click)="save(false, undefined, undefined)" type="button" class="btn btn-primary" [disabled]="!apartmentChosen">
                {{'RemoteConnectivity.Wizard.Connect.Controls.SubmitForm'|translate}}
            </button>
        </div>
    </div>

    <ng-template #feed let-feed='feed' let-name='name'>
        <i tooltip="{{feed}}" placement="left" [class]="feedTooltipClass"></i> {{name}}
    </ng-template>
</div>

<div *ngIf="mode === 'set-name'">
    <div class="card my-3">
        <div class="card-body">
            <h2 class="card-title">{{'RemoteConnectivity.Wizard.SetName.Title'|translate}}</h2>
            <form [formGroup]="apartmentForm" (submit)="createApartmentProcess()">
                <div class="form-group">
                    <label for="name">{{'RemoteConnectivity.Wizard.SetName.Controls.Name'|translate}}</label>
                    <input #nameControl class="form-control" id="name" type="text" formControlName="name" required>
                    <control-messages [control]="apartmentForm.get('name')"></control-messages>
                </div>

                <div class="mt-4">
                    <button class="btn btn-primary" id="dsRemoteConnectivityComponentButtonSetNameSubmit" type="submit" [disabled]="!apartmentForm.valid">
                        {{'RemoteConnectivity.Wizard.SetName.Controls.SubmitForm'|translate}}</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div *ngIf="mode === 'completed'">
    <div class="card my-3">
        <div class="card-body">
            <h2 class="card-title">{{'RemoteConnectivity.Wizard.Completed.Title'|translate}}</h2>
            <div class="card-text">{{'RemoteConnectivity.Wizard.Completed.Text'|translate}}</div>
        </div>
    </div>
</div>
