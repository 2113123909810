
<h2 class="mb-3">{{'AppInstanceManagement.Title'|translate}}</h2>
<messages></messages>

<div class="row">
    <div class="col-12 col-lg-6" *ngFor="let appInstance of availableAppInstances">
        <div class="card my-2">
            <app-instance-collapse [id]="appInstance.app_name" [appInstance]="appInstance" [apartmentId]="apartmentId" (removeItem)="removeItem($event)"></app-instance-collapse>
        </div>
    </div>
</div>
<p *ngIf="!availableAppInstances.length">
    {{'AppInstanceManagement.NoAppInstances'|translate}}
</p>
