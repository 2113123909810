import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Salutation } from '../models/salutation.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SalutationService {

  items: Salutation[] = [];
  salutationCodes: string[] = ['Male', 'Female', 'Family'];

  constructor(
    private translate: TranslateService
  ) {

    this.salutationCodes.forEach(element => {
      const salutation = new Salutation();
      salutation.uid = element;
      this.items.push(salutation);
    });

    this.prepareSortedItems();

    translate.onLangChange.subscribe(() => {
      this.prepareSortedItems();
    });
  }

  async prepareSortedItems() {
    const translate = this.translate;

    for (const sc of this.items) {
      sc.name = await firstValueFrom(translate.get('Salutation.' + sc.uid));
    }

    this.items.sort((a, b) => {
      const lang = translate.currentLang ? translate.currentLang.substring(0, 2) : 'en';
      return a.name.localeCompare(b.name, lang);
    });
  }
}
