import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseFunctionsService } from './services/firebase-functions.service';
import { Observable } from 'rxjs';
import { User } from './models/user.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ds-account';

  isEmailVerified = true;
  deleteRequested = false;
  email: string;
  language = '';
  isAuthorized = false;
  fullName: string;
  showWelcomeMessage: boolean;
  onboardingProcess = false;
  user$: Observable<User>;

  get dsSiteLink() {
    const [subdomain] = document.location.hostname.split('.');
    switch (true) {
      case subdomain.startsWith('dev-accounts'):
        return 'https://stage.digitalstrom.com';
      case subdomain.startsWith('staging-accounts'):
        return 'https://beta.digitalstrom.com';
      case subdomain.startsWith('accounts'):
        return 'https://digitalstrom.com';
      default:
        return 'https://stage.digitalstrom.com';
    }
  }

  constructor(
    public auth: AuthService,
    private translate: TranslateService,
    private router: Router,
    private functions: FirebaseFunctionsService,
    activatedRoute: ActivatedRoute
  ) {
    this.user$ = this.auth.user$;

    this.user$.subscribe((user) => {
      if (user) {
        this.initializeUser(user);
      } else {
        this.clearUserData();
      }
    });

    this.router.events.subscribe(() => {
      this.showWelcomeMessage = !this.router.url.includes('/signin-gateway');
      this.onboardingProcess = this.router.url.includes('/remote-connectivity');
    });

    activatedRoute.queryParams.subscribe(params => {
      const language = params.language;
      if (language) {
        if (language.startsWith('de')) {
          this.setLanguage('de_DE');
          return;
        }

        if (language.startsWith('nl')) {
          this.setLanguage('nl_NL');
          return;
        }

        if (language.startsWith('en')) {
          this.setLanguage('en_US');
          return;
        }
      }
    });
  }

  ngOnInit(): void {
    try {
      const language = localStorage.getItem('language');
      if (language === 'de_DE' || language === 'nl_NL' || language === 'en_US') {
        this.translate.use(language);
        return;
      }
    } catch { }

    const userLang = navigator.language || navigator.userLanguage;

    if (!userLang) {
      this.setLanguage('en_US');
      return;
    }

    if (userLang.startsWith('de')) {
      this.setLanguage('de_DE');
      return;
    }

    if (userLang.startsWith('nl')) {
      this.setLanguage('nl_NL');
      return;
    }

    this.setLanguage('en_US');
  }

  public async initializeUser(user: User): Promise<void> {
    this.isAuthorized = true;

    const currentUser = this.auth.getCurrentFirebaseUser();
    this.isEmailVerified = currentUser.emailVerified || currentUser.providerData[0].providerId === 'facebook.com';
    this.email = user.email;

    if (currentUser) { // subscription can pass outdated user object, by the time code execution is here, currentUser can already be null
      const fireStoreUser = await this.auth.getUserFromStore();
      this.fullName = fireStoreUser && (fireStoreUser.firstName || fireStoreUser.lastName)
        ? (fireStoreUser.firstName + ' ' + fireStoreUser.lastName)
        : '';
      if (fireStoreUser && fireStoreUser.deletionRequested != null) {
        this.deleteRequested = fireStoreUser.deletionRequested;
      }
    } else {
      this.fullName = '';
    }
  }

  clearUserData() {
    this.isAuthorized = false;
    this.isEmailVerified = true;
    this.email = '';
  }

  async setLanguage(selectedLanguage: string) {
    try {
      localStorage.setItem('language', selectedLanguage);
    } catch { }

    const currentUser = this.auth.getCurrentFirebaseUser();
    if (currentUser) {
      this.functions.updateUserAccount({
        uid: currentUser.uid,
        uiLanguage: selectedLanguage,
      })
        .catch(error => {
          console.log(error);
        });
    }
    this.translate.use(selectedLanguage);
    this.language = selectedLanguage;
  }

  hasRoute(route: string) {
    return this.router.url.includes(route);
  }
}
