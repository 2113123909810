import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessagesService } from '../services/messages.service';
import { ParameterService } from '../services/parameter.service';
import { FirebaseFunctionsService } from '../services/firebase-functions.service';
import { SpinnerService } from '../services/spinner.service';

interface FormData {
  email: FormControl<string>;
  password: FormControl<string>;
}

@Component({
  selector: 'signin-gateway',
  templateUrl: './signin-gateway.component.html',
  styleUrls: ['./signin-gateway.component.scss']
})
export class SigninGatewayComponent implements OnInit {

  form: FormGroup<FormData>;
  userCode: string;
  clientId: string;

  constructor(
    public auth: AuthService,
    public parameter: ParameterService,
    private router: Router,
    private spinner: SpinnerService,
    private formBuilder: FormBuilder,
    private messages: MessagesService,
    private activatedRoute: ActivatedRoute,
    private firebaseFunctions: FirebaseFunctionsService,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.userCode = params.user_code;
      this.clientId = params.client_id;
    });

    this.buildForm();
  }

  ngOnInit() {
    this
      .auth
      .getRedirectResult()
      .then(result => {
        if (!result || !result.user) {
          return;
        }
        this.validateUserCode();
      })
      .catch(error => {
        switch (error.code) {
          case 'auth/account-exists-with-different-credential':
            this.messages.pushErrorMessage('ProfileFederated.AccountExistsDifferentCredentials');
            break;
          default:
            console.log(error);
            this.messages.pushErrorMessage('ProfileFederated.UnknownError');
        }
      });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  async authenticateUser(email: string, password: string): Promise<void> {
    this.spinner.show(true);
    this.messages.clear();

    await this.auth
      .confirmPassword(email, password)
      .then(async () => {
        const currentUser = this.auth.getCurrentFirebaseUser();
        if (currentUser.emailVerified === true) {
          const u = await this.auth.getUserFromStore();
          if (u.confirmed === false) {
            await this.firebaseFunctions.confirmUserAccountEmail(currentUser.uid);
          }
        }

        await this.emailVerifiedAccountComplete().then(async resolve => {
          this.validateUserCode();
        }).catch(error => {
          this.spinner.hide();
          this.messages.pushErrorMessage('Auth.AccountNotCompleted');
          // this.sendRemoteConnectivityStatusError();
          return;
        });
      }).catch(error => {
        this.spinner.hide();

        if (error.code === 'auth/wrong-password') {
          this.messages.pushErrorMessage('Auth.WrongPassword');
        }
        else if (error.code === 'auth/user-not-found') {
          this.messages.pushErrorMessage('Auth.UserNotFound');
        }
        else if (error.code === 'auth/too-many-requests') {
          this.messages.pushErrorMessage('Auth.TooManyRequests');
        }
        else {
          console.log(error);
          this.messages.pushErrorMessage('Auth.SignInFailed');
        }

        return;
      });
  }

  authenticateUserThroughProvider(target: string) {
    if (target === 'Google') {
      this.signInWithGoogle(false);
    } else if (target === 'Facebook') {
      this.signInWithFacebook(false);
    } else if (target === 'Apple') {
      this.signInWithAppleId(false);
    }
  }

  signInWithGoogle(accepted: boolean): Promise<void> {
    return this.signIn(() => this.auth.signInWithGoogleWithPopup(accepted));
  }

  private async signIn(signInMethod: SignInMethod): Promise<void> {
    try {
      this.spinner.show();
      try {
        await signInMethod();

        const currentUser = this.auth.getCurrentFirebaseUser();
        if (currentUser.emailVerified) {
          const u = await this.auth.getUserFromStore();

          if (!u.confirmed) {
            await this.firebaseFunctions.confirmUserAccountEmail(currentUser.uid);
          }
        }

        try {
          await this.emailVerifiedAccountComplete();
          await this.validateUserCode();
        } catch (error) {
          this.spinner.hide();
          this.messages.pushErrorMessage('Auth.AccountNotCompleted');
          // this.sendRemoteConnectivityStatusError();
        }
      } catch (error) {
        this.spinner.hide();

        if (error.code === 'auth/wrong-password') {
          this.messages.pushErrorMessage('Auth.WrongPassword');
          return;
        }

        if (error.code === 'auth/user-not-found') {
          this.messages.pushErrorMessage('Auth.UserNotFound');
          return;
        }

        if (error.code === 'auth/too-many-requests') {
          this.messages.pushErrorMessage('Auth.TooManyRequests');
          return;
        }

        console.log(error);
        this.messages.pushErrorMessage('Auth.SignInFailed');
      }
    } catch (error) {
      console.log(error);
    }
  }

  signInWithAppleId(accepted: boolean): Promise<void> {
    return this.signIn(() => this.auth.signinWithAppleIdWithPopup(accepted));
  }

  signInWithFacebook(accepted: boolean): Promise<void> {
    return this.signIn(() => this.auth.signInWithFacebookWithPopup(accepted));
  }

  async validateUserCode() {
    this.messages.clear();
    await this.firebaseFunctions.validateUserCode(this.userCode, this.clientId, '1').then(async response => {
      await this.firebaseFunctions.verifyUniqueMacAddressForFeed(response.dsid, response.dss_feed, response.mac).then(() => {
        this.spinner.hide();
        this.router.navigateByUrl(`/remote-connectivity?dssid=${response.dsid}&feed=${response.dss_feed}&mac=${response.mac}&usercode=${this.userCode}&clientid=${this.clientId}`);
      }).catch(async error => {
        this.messages.pushErrorMessage('Error verifying DSS MAC address. ' + JSON.stringify(error.error.error.detail));
        const appigeeBody = {
          user_code: this.userCode,
          error: {
            status_code: 409,
            error_detail: {
              error: 'dSS is already associated with another apartment of another user on the same dSS Feed',
              error_description: 'dss is registered to another user. User needs to contact dS Support',
              error_uri: '',
              error_ds_specific_code: 'dss_already_connected'
            }
          }
        };
        await this.firebaseFunctions.remoteConnectivityStatus(appigeeBody);
        this.spinner.hide();
        this.router.navigateByUrl('/remote-connectivity-failure?error=other&macAddress=' + response.mac + '&dSId=' + response.dsid);
      });

    }).catch(async error => {
      this.messages.pushErrorMessage('User code could not be verified. ' + JSON.stringify(error.error.error.detail.error_description));
      const appigeeBody = {
        user_code: this.userCode,
        error: {
          status_code: 408,
          error_detail: {
            error: 'Device Code expired',
            error_description: 'Time limit for on-boarding process expired',
            error_uri: '',
            error_ds_specific_code: 'device_code_expired'
          }
        }
      };
      await this.firebaseFunctions.remoteConnectivityStatus(appigeeBody).then(() => {
        this.spinner.hide();
        this.auth.signOut();
        this.router.navigateByUrl('/remote-connectivity-failure?error=code_expired');
      }).catch(() => {
        this.spinner.hide();
        this.auth.signOut();
      });

    });
  }

  public async emailVerifiedAccountComplete(): Promise<boolean> {
    try {
      const user = this.auth.getCurrentFirebaseUser();

      if (!user.emailVerified) {
        return Promise.reject(false);
      }

      const firestoreUser = await this.firebaseFunctions.getUserAccount(user.uid);

      if (firestoreUser.confirmed.valueOf() === true && firestoreUser.state.valueOf() === 'Complete') {
        return true;
      }

      return Promise.reject(false);
    } catch {
      return Promise.reject(false);
    }
  }
}

type SignInMethod = () => Promise<void>;
