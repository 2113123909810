import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BrowserService } from '../services/browser.service';

@Injectable({
    providedIn: 'root'
})
export class BrowserSupportedGuard implements CanActivate {

    constructor(
        private browserService: BrowserService,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const result = this.browserService.isSupported();

        if (!result) {
            this.router.navigate(['/browser-not-supported']);
        }

        return result;
    }
}
