import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '../services/spinner.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApartmentResponse } from '../models/apartment/ApartmentResponse';
import { ConnectionResponse } from './ConnectionResponse';
import { attemptedConnectionType } from './attemptedConnectionType';
import { Apartment } from '../models/apartment/Apartment';
import { FirebaseFunctionsService } from '../services/firebase-functions.service';
import { MessagesService } from '../services/messages.service';
import { AuthService } from '../services/auth.service';

interface FormData {
  name: FormControl<string>;
}

@Component({
  selector: 'app-remote-connectivity',
  templateUrl: './remote-connectivity.component.html',
  styleUrls: ['./remote-connectivity.component.scss']
})
export class RemoteConnectivityComponent implements OnInit {

  public mode = 'wizard';
  public attemptedConnectionType = attemptedConnectionType;
  public connectionType: attemptedConnectionType = attemptedConnectionType.Reconnect;
  public availableApartments: Apartment[];
  public selectedApartment: string;
  public permittedFeeds: string[];
  public apartmentForm: FormGroup<FormData>;

  apartmentsLoaded = false;
  dssConnectionLoaded = false;
  apartmentChosen = false;

  dssId: string;
  feed: string;
  mac: string;
  userCode: string;
  clientId: string;

  constructor(
    public auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: SpinnerService,
    private formBuilder: FormBuilder,
    private messages: MessagesService,
    private functions: FirebaseFunctionsService,
  ) {
    this.dssId = this.route.snapshot.queryParams.dssid;
    this.feed = this.route.snapshot.queryParams.feed || 'production';
    this.mac = this.route.snapshot.queryParams.mac;
    this.userCode = this.route.snapshot.queryParams.usercode;
    this.clientId = this.route.snapshot.queryParams.clientid;

    if (!this.dssId) {
      this.mode = 'parameter-error';
    }
  }

  ngOnInit() {
    this.loadUserApartments();
    this.loadDssConnection();

    this.buildForm();
  }

  private async buildForm(): Promise<void> {
    this.apartmentForm = this.formBuilder.group({
      name: ['', [Validators.required]],
    });
  }

  private getSortableValue(feed: string): number {
    // order results by feed:
    // 1. production
    // 2. beta
    // 3. alpha
    // 4. development

    switch (feed) {
      case 'development':
        return 4;
      case 'alpha':
        return 3;
      case 'beta':
        return 2;
      case 'production':
        return 1;
    }

    return 99;
  }

  private sortApartments(a: Apartment, b: Apartment): number {
    const feedSort = this.getSortableValue(a.feed) - this.getSortableValue(b.feed);
    if (feedSort === 0) {
      return a.name.localeCompare(b.name);
    }

    return feedSort;
  }

  private mapApartmentsFromApiResult(apiResult: ApartmentResponse): Apartment[] {
    const result: Apartment[] = [];

    if (apiResult && apiResult.data && apiResult.data.attributes && apiResult.data.attributes.apartments) {
      apiResult.data.attributes.apartments.forEach(apartmentResult => {
        const apartment: Apartment = {
          id: apartmentResult.id,
          dssId: apartmentResult.attributes.dssId,
          feed: apartmentResult.attributes.feed,
          name: apartmentResult.attributes.name,
          pagekiteLink: apartmentResult.attributes.pagekiteLink,
          pagekitePassword: apartmentResult.attributes.pagekitePassword,
          ospToken: apartmentResult.attributes.ospToken,
          macAddress: apartmentResult.attributes.macAddress,
          inDeletionProcess: apartmentResult.attributes.inDeletionProcess,
          inDisconnectProcess: apartmentResult.attributes.inDisconnectProcess,
          // backups: null
        };

        if (apartment.dssId === this.dssId) {
          this.selectedApartment = apartment.id;
        }

        result.push(apartment);
      });
    }

    const sortedResult = result.sort((a, b) => this.sortApartments(a, b));

    return sortedResult;
  }

  private mapConnectionTypeFromApiResult(apiResult: ConnectionResponse): attemptedConnectionType {
    if (apiResult && apiResult.data && apiResult.data.attributes) {
      if (!apiResult.data.attributes.apartmentId && apiResult.data.attributes.mapped) {
        return attemptedConnectionType.Blocked; // DSS belongs to another user
      }
      else if (apiResult.data.attributes.apartmentId && apiResult.data.attributes.mapped) {
        return attemptedConnectionType.Reconnect; // DSS is connected to Apartment owned by user
      }
      else if (!apiResult.data.attributes.mapped) {
        return attemptedConnectionType.Connect; // DSS wasn't connected yet
      }
    }
  }

  private async loadUserApartments(): Promise<void> {
    if (this.apartmentsLoaded) {
      return;
    }

    this.apartmentsLoaded = true;

    this.spinner.show(false);

    try {
      const apiResult = await this.functions.getApartments();
      this.availableApartments = this.mapApartmentsFromApiResult(apiResult);
    }
    catch (error) {
      this.messages.pushErrorMessage('RemoteConnectivity.Error.LoadApartmentsFailed');
      console.log(error);
    }
    finally {
      this.spinner.hide();
    }
  }

  private async loadDssConnection(): Promise<void> {
    if (this.dssConnectionLoaded) {
      return;
    }

    this.dssConnectionLoaded = true;

    this.spinner.show(false);
    try {
      const apiResult = await this.functions.getDssConnection(this.dssId, this.feed);
      this.connectionType = this.mapConnectionTypeFromApiResult(apiResult);
    }
    catch (error) {
      this.messages.pushErrorMessage('RemoteConnectivity.Error.LoadDssConnectionFailed');
      console.log(error);
    }
    finally {
      this.spinner.hide();
    }
  }

  public async createApartmentProcess() {
    try {
      this.functions.validateUserCode(this.userCode, this.clientId, '2').then(async resp => {
        if (this.selectedApartment === 'new') {
          console.log('User code validated.');
          this.functions.createPagekiteLink(this.dssId).then(async response => {
            console.log('Pagekite link created.');
            console.log(response);
            await this.save(true, response.url, response.password);
          }).catch(async error => {
            const apigeeBody = {
              user_code: this.userCode,
              error: {
                status_code: 404,
                error_detail: {
                  error: 'Saving apartment information in backend failed',
                  error_description: 'Firebase had complications with PageKite registration. Error message from pagekite servive: ' + error.message,
                  error_uri: '',
                  error_ds_specific_code: 'backend_timeout'
                }
              }
            };
            await this.functions.remoteConnectivityStatus(apigeeBody);
            console.log(error);
            this.spinner.hide();
            this.router.navigateByUrl('/remote-connectivity-failure?error=pagekite_failed');
          });
        }
        else {
          const apartment = (await this.functions.getApartments()).data.attributes.apartments[0];
          this.functions
            .reestablishPagekiteLink(apartment.attributes.pagekiteLink, apartment.attributes.pagekitePassword, this.dssId, apartment.id)
            .then(async response => {
              await this.save(true, apartment.attributes.pagekiteLink, apartment.attributes.pagekitePassword);
            }).catch(async error => {
              const apigeeBody = {
                user_code: this.userCode,
                error: {
                  status_code: 404,
                  error_detail: {
                    error: 'Saving apartment information in backend failed',
                    error_description: 'Firebase had complications with PageKite registration. Error message from pagekite servive: ' + error.message,
                    error_uri: '',
                    error_ds_specific_code: 'backend_timeout'
                  }
                }
              };
              await this.functions.remoteConnectivityStatus(apigeeBody);
              console.log(error);
              this.spinner.hide();
              this.router.navigateByUrl('/remote-connectivity-failure?error=pagekite_failed');
            });
        }
      }).catch(async error => {
        const apigeeBody = {
          user_code: this.userCode,
          error: {
            status_code: 408,
            error_detail: {
              error: 'Device Code expired',
              error_description: 'Time limit for on-boarding process expired',
              error_uri: '',
              error_ds_specific_code: 'device_code_expired'
            }
          }
        };
        await this.functions.remoteConnectivityStatus(apigeeBody);
        console.log(error);
        this.spinner.hide();
        this.router.navigateByUrl('/remote-connectivity-failure?error=code_expired&macAddress=' + this.mac + '&dSId=' + this.dssId);
      });
    } catch (error) {
      console.log(error);
      this.router.navigateByUrl('/remote-connectivity-failure?error=default');
    }
    this.spinner.show(true);

  }

  public async save(formCompleted: boolean = false, pagekite: string, pagekitePassword: string) {
    try {
      console.log('Saving apartment');
      this.spinner.show(false);

      // When a NEW apartment is onboarded
      if (this.selectedApartment === 'new') {
        this.messages.clear();
        let apartmentName = '';
        if (!formCompleted || this.apartmentForm.invalid) {
          this.mode = 'set-name';
          return;
        }

        apartmentName = this.apartmentForm.value.name;
        const u = await this.auth.getUserFromStore();

        const body = {
          data: {
            type: 'apartment',
            attributes: {
              name: apartmentName,
              feed: this.feed,
              dssId: this.dssId,
              macAddress: this.mac,
              pageKiteLink: pagekite,
              pageKitePassword: pagekitePassword,
              email: u.email,
              inDeletionProcess: false
            }
          }
        };
        const response = await this.functions.createApartment(body);

        console.log('Apartment created.');

        const apigeeBody = {
          user_code: this.userCode,
          data: {
            access_token: response.ospToken,
            token_type: 'osp_token',
            relay_name: pagekite.replace('.digitalstrom.net', ''),
            relay_password: pagekitePassword,
            user_email: u.email,
            apartment_id: response.apartmentId.value
          }
        };

        await this.functions.remoteConnectivityStatus(apigeeBody);
        console.log('Remote connectivity status sent.');
      }

      // When an EXISTING apartment is onboarded
      else {
        const apartments = this.availableApartments;
        let apartment: Apartment = null;

        apartments.forEach((a, i) => {
          if (a.id === this.selectedApartment) {
            apartment = a;
          }
        });

        const u = await this.auth.getUserFromStore();

        const body = {
          data: {
            type: 'apartment',
            attributes: {
              name: apartment.name,
              feed: this.feed,
              dssId: this.dssId,
              macAddress: this.mac,
              pageKiteLink: pagekite,
              pageKitePassword: pagekitePassword,
              email: u.email,
              inDeletionProcess: false,
              inDisconnectProcess: false
            }
          }
        };

        const response = await this.functions.updateApartment(this.selectedApartment, body);
        await this.functions.deleteApartmentDataFromApartmentDisconnect(apartment.id);

        console.log('Update Apartment');
        console.log(apartment);

        const apigeeBody = {
          user_code: this.userCode,
          data: {
            access_token: response.ospToken,
            token_type: 'osp_token',
            relay_name: apartment.pagekiteLink.replace('.digitalstrom.net', ''),
            relay_password: apartment.pagekitePassword,
            user_email: u.email,
            apartment_id: apartment.id
          }
        };

        await this.functions.remoteConnectivityStatus(apigeeBody);
        console.log('Remote Connectivity Status sent');
      }
      this.spinner.hide();
      this.router.navigateByUrl('/remote-connectivity-success');
    }
    catch (error) {
      this.messages.setMessage('RemoteConnectivity.Error.SaveChangesFailed');
      const apigeeBody = {
        user_code: this.userCode,
        error: {
          status_code: 404,
          error_detail: {
            error: 'Saving apartment information in database failed',
            error_description: 'Firebase had complications accessing Firestore',
            error_uri: '',
            error_ds_specific_code: 'backend_timeout'
          }
        }
      };
      await this.functions.remoteConnectivityStatus(apigeeBody);
      console.log(error);
      this.spinner.hide();
      this.router.navigateByUrl('/remote-connectivity-failure?error=default');
    }
    finally {
      this.spinner.hide();
    }
  }

  get feedTooltipClass() {
    switch (this.feed) {
      case 'production':
        return 'fa fa-heartbeat text-success';
      case 'beta':
        return 'fa fa-heart text-success';
      case 'alpha':
        return 'fa fa-heart-o text-warning';
      default:
        return 'fa fa-flask text-danger';
    }
  }
}
