// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { FirebaseOptions } from '@angular/fire/app';

export const environment: Environments = {
  dev: {
    production: false,
    firebaseFunctionsRegion: 'europe-west1',
    firebaseConfig: {
      apiKey: 'AIzaSyCjmhfFSrUbhUkdyVCe2NlUMeSR61JtteY',
      authDomain: 'dev-accounts.digitalstrom.com',
      databaseURL: 'https://ds-account-dev.firebaseio.com',
      projectId: 'ds-account-dev',
      storageBucket: 'ds-account-dev.appspot.com',
      messagingSenderId: '49801341485',
      appId: '1:49801341485:web:3e0a5c426f26558ce326a4',
      measurementId: 'G-H7QXE9FPKP'
    }
  },
  staging: {
    production: false,
    firebaseFunctionsRegion: 'europe-west1',
    firebaseConfig: {
      apiKey: 'AIzaSyDAwUof5RXLdxzGNId2lHGLQOPqKe_scyI',
      authDomain: 'staging-accounts.digitalstrom.com',
      databaseURL: 'https://ds-account-staging-dab14.firebaseio.com',
      projectId: 'ds-account-staging-dab14',
      storageBucket: 'ds-account-staging-dab14.appspot.com',
      messagingSenderId: '302509659661',
      appId: '1:302509659661:web:02ee170407e2c7efad2744',
      measurementId: 'G-9NYL28JVEY'
    }
  },
  prod: {
    production: true,
    firebaseFunctionsRegion: 'europe-west1',
    firebaseConfig: {
      apiKey: 'AIzaSyBqDxiqhHQwLCsLW0l8H-7OreA0A_VU81k',
      authDomain: 'accounts.digitalstrom.com',
      databaseURL: 'https://ds-account.firebaseio.com',
      projectId: 'ds-account',
      storageBucket: 'ds-account.appspot.com',
      messagingSenderId: '597976954424',
      appId: '1:597976954424:web:30579ff886059f42a398cd',
      measurementId: 'G-1DFB9XX1QQ'
    }
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

export interface Environment {
  production: boolean;
  firebaseFunctionsRegion: string;
  firebaseConfig: FirebaseOptions;
}

export interface Environments {
  dev: Environment;
  staging: Environment;
  prod: Environment;
}
