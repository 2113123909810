import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MessagesService } from '../services/messages.service';
import { SpinnerService } from '../services/spinner.service';

@Component({
  selector: 'delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {

  public email: string;

  constructor(
    public auth: AuthService,
    private router: Router,
    private messages: MessagesService,
    private spinner: SpinnerService
  ) { }

  ngOnInit() {
    this.auth.user$.subscribe(u => {
      if (!u) {
        this.router.navigate(['/signin']);
        return;
      }

      this.email = u.email;
    });
  }

  public async deleteAccount() {
    try {
      this.spinner.show(true);

      const currentUser = this.auth.getCurrentFirebaseUser();
      currentUser.delete();

      this.router.navigate(['/signin']);
    }
    catch (error) {
      this.spinner.hide();

      if (error.code === 'auth/requires-recent-login') {
        this.messages.setMessage('Delete.RequiresLogin');
        await this.auth.signOut();
      }
      else {
        console.log(error);
        this.messages.setMessage('Delete.Failed');
      }
    } finally {
      this.spinner.hide();
    }
  }

  public abort() {
    this.router.navigate(['/profile']);
  }
}
