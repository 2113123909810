<div *ngIf="!isVerified" class="row justify-content-center">
    <div class="col-12 col-md-9 col-lg-8">
        <div class="alert alert-warning Messages.Profile.EmailVerificationPending alert-dismissible"
        role="alert" id="message-verification-pending">
            <div [innerHtml] = "'Messages.Profile.EmailVerificationPending'|translate:this"></div>
            <div>
                {{ clickHereToChangeEmailMessage.first }}
                <a [routerLink]="[]" id="dsVerificationPendingLinkChangeEmail" (click)="changeEmail()">{{ clickHereToChangeEmailMessage.link }}</a>
                {{ clickHereToChangeEmailMessage.last }}
            </div>
            <div>{{ clickToSendVerificationEmailMessage.first }}
                <a [routerLink]="[]" id="dsVerificationPendingLinkSendEmailVerification" (click)="sendEmailVerification()">{{ clickToSendVerificationEmailMessage.link }}</a>
                {{ clickToSendVerificationEmailMessage.last }}
            </div>
        </div>
        <hr class="subtle mt-4"/>
    </div>
</div>
