<button type="button" class="btn btn-light w-100 py-3 px-2 text-start no-border-radius" data-app-toggle-button
(click)="toggleCollapseState()" [attr.aria-expanded]="!isCollapsed" [attr.aria-controls]="id">
    <ng-container [ngTemplateOutlet]="header" [ngTemplateOutletContext]="{appInstance:appInstance}"></ng-container>
</button>
<div [id]="id" *ngIf="!isCollapsed">
    <div class="d-flex p-2">
        <ng-container [ngTemplateOutlet]="feed" [ngTemplateOutletContext]="{appInstance:appInstance}"></ng-container>
    </div>
</div>

<ng-template #feed let-appInstance='appInstance'>
    <div class="table-responsive">
        <ng-container [ngTemplateOutlet]="appInstanceContainer" [ngTemplateOutletContext]="{appInstance:appInstance}"></ng-container>
    </div>
</ng-template>

<ng-template #header let-appInstance='appInstance'>
    <div class="container-fluid">
        <div class="row justify-content-between" [attr.data-state]="isCollapsed ? 'collapsed' : 'expanded'">
            <div class="col-10 col-sm-11" [attr.data-app-name]="appInstance.app_name">
                {{appInstance.app_name}}
            </div>
            <div class="col-2 col-sm-1 ms-auto text-end">
                <i [class]="isCollapsed ? 'fa fa-chevron-down' : 'fa fa-chevron-up'" placement="right"></i>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #appInstanceContainer let-appInstance='appInstance'>
    <div class="container-fluid">
        <h4 class="my-3">{{'AppInstanceManagement.Details.AppInstances'|translate}}</h4>
        <div *ngFor="let singleAppInstance of appInstance.appinstances" class="row my-3" [attr.data-app-instance-id]="singleAppInstance.app_instance_id">
            <div class="col-12">
                <hr class="my-2" />
            </div>
            <div class="col-12 col-sm-auto lh-125 force-wrap">
                <b class="me-1" data-app-instance-name>{{singleAppInstance.app_instance_name}}</b>
                <span class="small block" [attr.data-app-instance-creation-date]="singleAppInstance.creation_date">{{'AppInstanceManagement.Details.AppCreatedOn'|translate}}: {{singleAppInstance.creation_date}}</span>
            </div>
            <div class="col-12 col-sm-auto ms-sm-auto text-center">
                <button class="btn btn-danger text-nowrap mt-1 mt-sm-0" id="dsAppInstanceRevokeButtonOpenModal" (click)="openRevokeAppInstanceModal(singleAppInstance)"
                data-revoke-button>
                    {{'AppInstanceManagement.Details.RevokeButton'|translate}}
                </button>
            </div>
        </div>
    </div>
</ng-template>
