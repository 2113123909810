import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RedirectService {
    public ToClientUri(redirectUri: string): void {
        window.location.href = redirectUri;
    }
}
