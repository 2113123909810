import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessagesService } from './messages.service';
import {
  AuthProvider, signInWithEmailAndPassword, Auth, authState, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider,
  signInWithRedirect, signOut, User as FirebaseUser, getRedirectResult, UserCredential, updatePassword, sendPasswordResetEmail,
  signInWithPopup, sendEmailVerification, createUserWithEmailAndPassword, fetchSignInMethodsForEmail, updateEmail
} from '@angular/fire/auth';
import { SpinnerService } from './spinner.service';
import { ParameterService } from './parameter.service';
import { FirebaseFunctionsService } from './firebase-functions.service';
import { UsersService } from './users.service';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<User>;

  constructor(
    private params: ParameterService,
    private auth: Auth,
    private router: Router,
    private messages: MessagesService,
    private spinner: SpinnerService,
    private functions: FirebaseFunctionsService,
    private userService: UsersService
  ) {
    this.user$ = authState(this.auth)
      .pipe(
        switchMap(user => {
          if (user) {
            return userService.GetUserObservableDoc(user.uid);
          }
          else {
            return of(null);
          }
        })
      );
  }

  public async createUserWithEmailAndPassword(email: string, password: string): Promise<void> {
    await createUserWithEmailAndPassword(this.auth, email, password);
  }

  public fetchSignInMethodsForEmail(email: string): Promise<string[]> {
    return fetchSignInMethodsForEmail(this.auth, email);
  }

  public getCurrentFirebaseUser(): FirebaseUser {
    return this.auth.currentUser;
  }

  public getIdToken(): Promise<string> {
    return this.getCurrentFirebaseUser().getIdToken();
  }

  public getRedirectResult(): Promise<UserCredential | null> {
    return getRedirectResult(this.auth);
  }

  public async getUserFromStore(): Promise<User> {
    const user = this.getCurrentFirebaseUser();
    return await this.userService.GetUserDoc(user.uid);
  }

  public get languageCode(): string {
    return this.auth.languageCode;
  }

  public set languageCode(code: string) {
    this.auth.languageCode = code;
  }

  public sendEmailVerification(user: FirebaseUser): Promise<void> {
    return sendEmailVerification(user);
  }

  public sendPasswordResetEmail(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }

  public signIn() {
    this.router.navigateByUrl('/signin' + this.params.getRequestQueryParametersString());
  }

  private async signInWithPopup(accepted: boolean, provider: AuthProvider): Promise<void> {
    localStorage.setItem('termsAccepted', accepted.toString());
    await signInWithPopup(this.auth, provider);
  }

  private async signInWithRedirect(accepted: boolean, provider: AuthProvider): Promise<void> {
    try {
      localStorage.setItem('termsAccepted', accepted.toString());
      this.router.navigate([], {
        queryParams: {
          relogin: null,
        },
        queryParamsHandling: 'merge'
      });
      await signInWithRedirect(this.auth, provider);
    }
    catch (error) {
      console.log(error);
    }
  }

  public signInWithAppleIdWithRedirect(accepted: boolean): Promise<void> {
    const provider = new OAuthProvider('apple.com');
    return this.signInWithRedirect(accepted, provider);
  }

  public async signinWithAppleIdWithPopup(accepted: boolean): Promise<void> {
    const provider = new OAuthProvider('apple.com');
    return this.signInWithPopup(accepted, provider);
  }

  public async confirmPassword(email: string, password: string): Promise<void> {
    await signInWithEmailAndPassword(this.auth, email, password);
  }

  public async defaultSignInWithEmailAndPasswordFlow(email: string, password: string): Promise<boolean> {
    this.messages.clear();
    this.spinner.show(true);

    try {
      await signInWithEmailAndPassword(this.auth, email, password);

      const currentUser = this.getCurrentFirebaseUser();

      if (!this.params.isRedirectAuthFlow()) {
        if (currentUser.emailVerified) {
          const u = await this.getUserFromStore();
          if (!u.confirmed) {
            this.functions.confirmUserAccountEmail(currentUser.uid);
          }
        }

        this.router.navigate(['/apartment-management']);
        return;
      }

      if (currentUser.emailVerified) {
        this.router.navigateByUrl('/consent' + this.params.getRequestQueryParametersString());
      }
      else {
        this.router.navigate(['/not-verified']);
      }
    }
    catch (error) {
      if (error.code === 'auth/wrong-password') {
        this.messages.setErrorMessage('Auth.WrongPassword');
      }
      else if (error.code === 'auth/user-not-found') {
        this.messages.setErrorMessage('Auth.UserNotFound');
      }
      else if (error.code === 'auth/too-many-requests') {
        this.messages.setErrorMessage('Auth.TooManyRequests');
      }
      else {
        console.log(error);
        this.messages.setErrorMessage('Auth.SignInFailed');
      }
    }
    finally {
      this.spinner.hide();
    }
  }

  public async signInWithFacebookWithPopup(accepted: boolean): Promise<void> {
    const provider = new FacebookAuthProvider();
    return this.signInWithPopup(accepted, provider);
  }

  public signInWithFacebookWithRedirect(accepted: boolean): Promise<void> {
    const provider = new FacebookAuthProvider();
    return this.signInWithRedirect(accepted, provider);
  }

  public signInWithGoogleWithPopup(accepted: boolean): Promise<void> {
    const provider = new GoogleAuthProvider();
    return this.signInWithPopup(accepted, provider);
  }

  public signInWithGoogleWithRedirect(accepted: boolean): Promise<void> {
    const provider = new GoogleAuthProvider();
    return this.signInWithRedirect(accepted, provider);
  }

  public async signOut() {
    this.spinner.show();
    try {
      await signOut(this.auth);

      this.router.navigateByUrl('/signin' + this.params.getRequestQueryParametersString());
    } finally {
      this.spinner.hide();
    }
  }

  public signUp() {
    this.router.navigateByUrl('/signup' + this.params.getRequestQueryParametersString());
  }

  public updateEmail(currentUser: FirebaseUser, newEmail: any): Promise<void> {
    return updateEmail(currentUser, newEmail);
  }

  public updatePassword(newPassword: string): Promise<void> {
    const user = this.getCurrentFirebaseUser();
    return updatePassword(user, newPassword);
  }
}
