import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Message } from '../models/message.model';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  private messages = new BehaviorSubject<Message[]>([]);
  private messageStore: { messages: Message[] } = { messages: [] };

  public readonly items = this.messages.asObservable();

  constructor() {
  }

  pushMessage(translationIdentifier: string, type: string = null, dictionary: object = null, values: object = null): void {
    const message = this.createMessage(translationIdentifier, type, dictionary);
    this.messageStore.messages.push(message);
    this.messages.next(Object.assign({}, this.messageStore).messages);
  }

  pushWarningMessage(translationIdentifier: string, dictionary: object = null, values: object = null): void {
    const message = this.createWarningMessage(translationIdentifier, dictionary);
    this.messageStore.messages.push(message);
    this.messages.next(Object.assign({}, this.messageStore).messages);
  }

  pushErrorMessage(translationIdentifier: string, dictionary: object = null, values: object = null): void {
    const message = this.createErrorMessage(translationIdentifier, dictionary);
    this.messageStore.messages.push(message);
    this.messages.next(Object.assign({}, this.messageStore).messages);
  }

  setMessage(translationIdentifier: string, type: string = null, dictionary: object = null, values: object = null) {
    const message = this.createMessage(translationIdentifier, type, dictionary);
    this.messageStore.messages = [message];
    this.messages.next(Object.assign({}, this.messageStore).messages);
  }

  setWarningMessage(translationIdentifier: string, dictionary: object = null, values: object = null) {
    const message = this.createWarningMessage(translationIdentifier, dictionary);
    this.messageStore.messages = [message];
    this.messages.next(Object.assign({}, this.messageStore).messages);
  }

  setErrorMessage(translationIdentifier: string, dictionary: object = null, values: object = null) {
    const message = this.createErrorMessage(translationIdentifier, dictionary);
    this.messageStore.messages = [message];
    this.messages.next(Object.assign({}, this.messageStore).messages);
  }

  clear() {
    this.messageStore.messages = [];
    this.messages.next(Object.assign({}, this.messageStore).messages);
  }

  delete(messageId: string) {
    const newMessages = [];
    this.messageStore.messages.forEach(message => {
      if (message.id !== messageId) {
        newMessages.push(message);
      }
    });

    this.clear();
    newMessages.forEach(newMessage => this.messageStore.messages.push(newMessage));
  }

  private createWarningMessage(translationIdentifier: string, dictionary: object = null, values: object = null) {
    return this.createMessage(translationIdentifier, 'warning', dictionary, values);
  }

  private createErrorMessage(translationIdentifier: string, dictionary: object = null, values: object = null) {
    return this.createMessage(translationIdentifier, 'danger', dictionary, values);
  }

  private createMessage(translationIdentifier: string, type: string = null, dictionary: object = null, values: object = null) {
    const message = new Message();
    message.translationIdentifier = translationIdentifier;
    message.values = values;
    if (type !== null) {
      message.type = type;
    }
    if (dictionary !== null) {
      message.dictionary = dictionary;
    }

    return message;
  }
}
