import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BrowserService } from '../services/browser.service';

@Component({
  selector: 'app-browser-not-supported',
  templateUrl: './browser-not-supported.component.html',
  styleUrls: ['./browser-not-supported.component.scss']
})
export class BrowserNotSupportedComponent implements OnInit {

  helpMessage: SplitResult = { first: '', link: '', last: '' };

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private browserService: BrowserService) {
    translateService.onLangChange.subscribe(() => {
      this.translate();
    });
    this.translate();
  }

  private translate(): void {
    this.translateService
      .get('Messages.HelpCenter')
      .subscribe(msg => {
        this.helpMessage = this.splitMessage(msg);
      });
  }


  ngOnInit(): void {
    const supported = this.browserService.isSupported();

    if (supported) {
      this.router.navigate(['/']);
    }
  }

  splitMessage(text: string): SplitResult {
    let parts = text.split('<a>');
    const first = parts[0];
    parts = parts[1].split('</a>');
    return {
      first,
      link: parts[0],
      last: parts[1]
    };
  }

  helpCenter(): void {
    window.location.href = 'https://help.digitalstrom.com/hc/en-us';
  }
}

interface SplitResult {
  first: string;
  link: string;
  last: string;
}
