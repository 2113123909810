import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslatePoHttpLoader } from '@fjnr/ngx-translate-po-http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlMessagesComponent } from './control-messages/control-messages.component';
import { SigninComponent } from './signin/signin.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MessagesComponent } from './messages/messages.component';
import { SignupComponent } from './signup/signup.component';
import { ProfileComponent } from './profile/profile.component';
import { DeleteComponent } from './delete/delete.component';
import { VerifyComponent } from './verify/verify.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ProfileFederatedComponent } from './profile-federated/profile-federated.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ConsentComponent } from './consent/consent.component';
import { RemoteConnectivityComponent } from './remote-connectivity/remote-connectivity.component';
import { ApartmentManagementComponent } from './apartment-management/apartment-management.component';
import { ApartmentComponent } from './apartment/apartment.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { AppInstanceManagementComponent } from './app-instance-management/app-instance-management.component';
import { AppInstanceCollapseComponent } from './app-instance-collapse/app-instance-collapse.component';
import { AppInstanceModalComponent } from './app-instance-modal/app-instance-modal.component';
import { VerificationPendingMessageComponent } from './verification-pending-message/verification-pending-message.component';
import { AccountDeleteMessageComponent } from './account-delete-message/account-delete-message.component';
import { SigninGatewayComponent } from './signin-gateway/signin-gateway.component';
import { RemoteConnectivityFailureComponent } from './remote-connectivity-failure/remote-connectivity-failure.component';
import { RemoteConnectivitySuccessComponent } from './remote-connectivity-success/remote-connectivity-success.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DeprecatedComponent } from './deprecated/deprecated.component';
import { NotVerifiedComponent } from './not-verified/not-verified.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore, enableIndexedDbPersistence } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { CookieModule } from 'ngx-cookie';
import { BrowserNotSupportedComponent } from './browser-not-supported/browser-not-supported.component';

@NgModule({
  declarations: [
    AppComponent,
    ControlMessagesComponent,
    SigninComponent,
    MessagesComponent,
    SignupComponent,
    ProfileComponent,
    DeleteComponent,
    VerifyComponent,
    ResetPasswordComponent,
    ProfileFederatedComponent,
    SpinnerComponent,
    ConsentComponent,
    RemoteConnectivityComponent,
    ApartmentManagementComponent,
    ApartmentComponent,
    ChangePasswordComponent,
    ChangeEmailComponent,
    AppInstanceManagementComponent,
    AppInstanceCollapseComponent,
    AppInstanceModalComponent,
    VerificationPendingMessageComponent,
    SigninGatewayComponent,
    RemoteConnectivityFailureComponent,
    RemoteConnectivitySuccessComponent,
    NotFoundComponent,
    AccountDeleteMessageComponent,
    DeprecatedComponent,
    NotVerifiedComponent,
    BrowserNotSupportedComponent
  ],
  imports: [
    provideFirebaseApp(injector => {
      const firebaseOptions = injector.get(FIREBASE_OPTIONS);
      return initializeApp(firebaseOptions);
    }),
    provideAuth(() => {
      const auth = getAuth();
      // if (emulator) connectAuthEmulator(auth, `http://localhost:${authPort}`);
      return auth;
    }),
    provideFunctions(() => {
      const functions = getFunctions();
      // if (emulator) connectFunctionsEmulator(functions, 'localhost', functionsPort);
      return functions;
    }),
    provideStorage(() => {
      const storage = getStorage();
      // if (emulator) connectStorageEmulator(storage, 'localhost', storagePort);
      return storage;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      // if (emulator) connectFirestoreEmulator(firestore, 'localhost', firestorePort);
      enableIndexedDbPersistence(firestore);
      return firestore;
    }),
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: http => new TranslatePoHttpLoader(http, './assets/translations/', '.po'),
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    CookieModule.withOptions(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.setDefaultLang('de_DE');
    // console.log('Environment: ' + (createConfigurationLoader().production) ? 'production' : 'development');
  }
}
