<div class="row justify-content-center">
    <div class="col-12 col-md-9 col-lg-8">
        <h2 class="mt-3">{{'SignIn.MainTitle'|translate}}</h2>
        <messages></messages>
        <p *ngIf="customGreetings" class="alert alert-primary">{{('CustomGreetings.' + customGreetings) | translate }}</p>

        <div class="card my-3">
            <h4 class="card-header">{{'SignIn.Title'|translate}}</h4>
            <div class="card-body">
                <form [formGroup]="form" (submit)="signIn(this.form.value.email, this.form.value.password)">
                    <div class="form-group">
                        <label for="email">{{'SignIn.Email'|translate}}</label>
                        <input #emailControl class="form-control" id="email" type="email" formControlName="email" placeholder="{{'SignIn.EmailPlaceholder'|translate}}" required>
                        <control-messages [control]="form.get('email')"></control-messages>
                    </div>
                    <div class="form-group">
                        <label for="password">{{'SignIn.Password'|translate}}</label>
                        <input #passwordControl class="form-control" id="password" type="password" formControlName="password" placeholder="{{'SignIn.PasswordPlaceholder'|translate}}" required>
                        <control-messages [control]="form.get('password')"></control-messages>
                    </div>

                    <div class="mt-4 clearfix">
                        <a tabindex="-1" class="btn btn-link ms--4" id="dsSignInGatewayComponentLinkResetPassword" [routerLink]="['/reset-password']">
                            {{'App.ResetPassword'|translate}}
                        </a>
                        <button class="btn btn-primary float-end" id="dsSignInGatewayComponentButtonSignin" type="submit" [disabled]="!form.valid">
                            {{'SignIn.Controls.SubmitForm'|translate}}
                        </button>
                    </div>
                </form>
                <p *ngIf="feed" class="mt-4" [translate]="'SignIn.FeedInfo'" [translateParams]="{ feed: this.feed }"></p>
                <p class="mt-4">
                    {{specialAccountInfo.first}} <a href="javascript:void(0)" (click)="setSpecialAccount()">{{specialAccountInfo.link}}</a>{{specialAccountInfo.last}}
                </p>
                <div *ngIf="specialAccount" class="mt-2">
                    <select class="form-select" [(ngModel)]="feedSelect" (change)="setFeed()">
                        <option [value]="feedSelect" selected disabled>{{feedSelect}}</option>
                        <option [value]="feed" *ngFor="let feed of feeds">{{feed}}</option>
                      </select>
                </div>
            </div>
        </div>

        <div class="card my-3">
            <h4 class="card-header">{{'SignIn.ThirdPartyProviderTitle'|translate}}</h4>
            <div class="card-body d-flex justify-content-center">
                <div class="d-grid col-12 col-md-3 mx-auto">
                    <button id="dsSignInComponentButtonGoogleSignup" class="btn social-media google mt-1" (click)="auth.signInWithGoogleWithRedirect(false)">
                        <img src="/assets/sign-in-with-google/google_g_logo.svg" width="26px" height="26px" /> {{'SignIn.Controls.SignInWithGoogle'|translate}}
                    </button>
                    <button id="dsSignInComponentButtonFacebookSignup" class="btn social-media facebook mt-1" (click)="auth.signInWithFacebookWithRedirect(false)">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16" id="facebook" width="26px" height="26px">
                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                        </svg>
                        {{'SignIn.Controls.SignInWithFacebook'|translate}}
                    </button>
                    <button id="dsSignInComponentButtonAppleSignup" class="btn social-media apple mt-1" (click)="auth.signInWithAppleIdWithRedirect(false)">
                        <i class="fa fa-apple"></i> {{'SignIn.Controls.SignInWithAppleId'|translate}}
                    </button>
                </div>
            </div>
        </div>

        <div class="card my-3">
            <div class="card-body d-flex justify-content-center">
                <div class="d-grid col-12 col-md-3 mx-auto">
                    <button class="btn btn-primary" (click)="auth.signUp()" id="signup">{{'App.SignUp'|translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
