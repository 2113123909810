import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MessagesService } from '../services/messages.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';

interface FormData {
  email: FormControl<string>;
}

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  form: FormGroup<FormData>;
  private email: string;

  constructor(
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private messages: MessagesService
  ) {
    this.email = this.route.snapshot.queryParams.email;
    this.buildForm();
  }

  buildForm() {
    let email = '';
    if (this.email) {
      email = this.email;
    }

    this.form = this.formBuilder.group({
      email: [email, [Validators.required, Validators.email]],
    });
  }

  async resetPassword(email: string) {
    try {
      await this.auth.sendPasswordResetEmail(email);
      this.messages.setMessage('ResetPassword.EmailSent');
    }
    catch (error) {
      switch (error.code) {
        case 'auth/invalid-email':
          this.messages.setMessage('ResetPassword.InvalidEmail');
          break;
        case 'auth/user-not-found':
          this.messages.setMessage('ResetPassword.UserNotFound');
          break;
        default:
          console.log(error);
          this.messages.setMessage('ResetPassword.Failed');
      }
    }
  }
}
