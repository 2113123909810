<div class="row justify-content-center">
    <div class="col-12 col-md-9 col-lg-8">
        <messages></messages>
        <div class="card">
            <h4 class="card-header">{{'Delete.Title'|translate}}</h4>
            <div class="card-body">
                <p [translate]="'Delete.Message'" [translateParams]="{emailAddress:email}"></p>

                <div class="text-center text-md-end">
                    <button class="btn btn-outline mx-2 mt-1" id="dsDeleteComponentButtonCancel" (click)="abort()">
                        {{'Delete.Controls.Cancel'|translate}}
                    </button>
                    <button class="btn btn-danger mt-1" id="dsDeleteComponentButtonDeleteAccount" (click)="deleteAccount()">
                        {{'Delete.Controls.SubmitForm'|translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
