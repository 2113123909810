import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PhoneValidatorService {

  constructor() { }

  public validator(control: AbstractControl): ValidationErrors {
    if (!control || !control.get('phone')) {
      return null;
    }

    if (control.get('phone').invalid) {
      control.get('phone').setErrors({wrongPhoneFormat: true});
    }
    return null;
  }
}
