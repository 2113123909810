import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Injectable({
    providedIn: 'root'
})
export class BrowserService {
    private isWorking = false;

    constructor(private cookieService: CookieService) {
    }

    public isSupported(): boolean {
        if (this.isWorking) {
            return true;
        }

        if (!this.isCookieAvailable()) {
            return false;
        }

        if (!this.isLocalStorageAvailable()) {
            return false;
        }

        this.isWorking = true;
        return true;
    }

    public isCookieAvailable(): boolean {
        try {
            this.cookieService.put('browserTest', 'browserTest', { sameSite: 'none', secure: true });

            if (this.cookieService.get('browserTest') !== 'browserTest') {
                return false;
            }

            this.cookieService.remove('browserTest');
            return true;
        } catch {
            return false;
        }
    }

    public isLocalStorageAvailable(): boolean {
        try {
            localStorage.setItem('browserTest', 'browserTest');

            if (localStorage.getItem('browserTest') !== 'browserTest') {
                return false;
            }

            localStorage.removeItem('browserTest');
            return true;
        } catch {
            return false;
        }
    }
}
