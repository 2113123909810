import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { AuthGuard } from './guards/auth.guard';
import { AnonymousGuard } from './guards/anonymous.guard';
import { SignupComponent } from './signup/signup.component';
import { ProfileComponent } from './profile/profile.component';
import { DeleteComponent } from './delete/delete.component';
import { VerifyComponent } from './verify/verify.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ProfileFederatedComponent } from './profile-federated/profile-federated.component';
import { FederatedAuthGuard } from './guards/federated-auth.guard';
import { ConsentComponent } from './consent/consent.component';
import { RemoteConnectivityComponent } from './remote-connectivity/remote-connectivity.component';
import { ApartmentManagementComponent } from './apartment-management/apartment-management.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CompleteAuthGuard } from './guards/complete-auth.guard';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { AppInstanceManagementComponent } from './app-instance-management/app-instance-management.component';
import { SigninGatewayComponent } from './signin-gateway/signin-gateway.component';
import { RemoteConnectivitySuccessComponent } from './remote-connectivity-success/remote-connectivity-success.component';
import { RemoteConnectivityFailureComponent } from './remote-connectivity-failure/remote-connectivity-failure.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DeprecatedComponent } from './deprecated/deprecated.component';
import { NotVerifiedComponent } from './not-verified/not-verified.component';
import { BrowserSupportedGuard } from './guards/browser-supported.guard';
import { BrowserNotSupportedComponent } from './browser-not-supported/browser-not-supported.component';

const routes: Routes = [
  { path: 'signin', component: SigninComponent, canActivate: [BrowserSupportedGuard, AnonymousGuard] },
  { path: 'signin-gateway', component: SigninGatewayComponent, canActivate: [BrowserSupportedGuard] },
  { path: 'signup', component: SignupComponent, canActivate: [BrowserSupportedGuard, AnonymousGuard] },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [BrowserSupportedGuard, AnonymousGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [BrowserSupportedGuard, AuthGuard, CompleteAuthGuard] },
  { path: 'profile/complete', component: ProfileFederatedComponent, canActivate: [BrowserSupportedGuard, AuthGuard] },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [BrowserSupportedGuard, AuthGuard] },
  { path: 'change-email', component: ChangeEmailComponent, canActivate: [BrowserSupportedGuard, AuthGuard] },
  { path: 'profile/delete', component: DeleteComponent, canActivate: [BrowserSupportedGuard, AuthGuard] },
  { path: 'profile/federated', component: ProfileFederatedComponent, canActivate: [BrowserSupportedGuard, FederatedAuthGuard] },
  { path: 'consent', component: ConsentComponent, canActivate: [BrowserSupportedGuard, AuthGuard] },
  { path: 'remote-connectivity', component: RemoteConnectivityComponent, canActivate: [BrowserSupportedGuard, AuthGuard, CompleteAuthGuard] },
  { path: 'apartment-management', component: ApartmentManagementComponent, canActivate: [BrowserSupportedGuard, AuthGuard, CompleteAuthGuard] },
  { path: 'verify', component: VerifyComponent, canActivate: [BrowserSupportedGuard] },
  { path: 'app-instance-management', component: AppInstanceManagementComponent, canActivate: [BrowserSupportedGuard, AuthGuard, CompleteAuthGuard] },
  { path: '', redirectTo: '/signin', pathMatch: 'full' },
  { path: 'remote-connectivity-success', component: RemoteConnectivitySuccessComponent },
  { path: 'remote-connectivity-failure', component: RemoteConnectivityFailureComponent },
  { path: 'deprecated', component: DeprecatedComponent },
  { path: 'not-verified', component: NotVerifiedComponent },
  { path: 'browser-not-supported', component: BrowserNotSupportedComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
