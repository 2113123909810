<div class="card-header p-0 w-100 text-start">
    <div class="row justify-content-between p-3">
        <div class="col-10 col-sm-11">
            <i tooltip="{{'ApartmentManagement.Feed.' + (apartment.feed|lowercase)|translate}}" placement="left" [class]="feedTooltipClass" [attr.data-feed]="apartment.feed"></i>

            <strong [attr.data-apartment-name]="apartment.name">
                {{apartment.name}}
            </strong>

        </div>
    </div>

    <div *ngIf="apartment.inDeletionProcess" class="bg-danger px-3 py-2" [attr.data-apartment-id-in-deletion-process]="apartment.id">
        <span class="text-white">
            <div class="spinner-border text-white me-2" style="width: 1.2rem; height: 1.2rem;" role="status">
            </div>
            {{'ApartmentManagement.Details.InDeletionProcess'|translate}}
        </span>
    </div>
    <div *ngIf="apartment.inDisconnectProcess" class="bg-warning px-3 py-2" [attr.data-apartment-id-in-disconnect-process]="apartment.id">
        <span class="text-white">
            {{'ApartmentManagement.Details.DssBeingDisconnected'|translate}}
        </span>
    </div>
    <div *ngIf="!apartment.dssId" class="bg-warning px-3 py-2" [attr.data-apartment-id-missing-connection]="apartment.id">
        <span class="text-white">
            {{'ApartmentManagement.Details.MissingDSSConnection'|translate}}
            <i tooltip="{{'ApartmentManagement.Details.MissingDSSConnectionInfo'|translate}}" placement="left" class="fa fa-info-circle"></i>
        </span>
    </div>
</div>

<div>
    <div class="table-responsive">
        <table class="table table-borderless">
            <tr>
                <th class="pe-2 text-end">{{'ApartmentManagement.Details.Feed'|translate}}</th>
                <td [attr.data-apartment-feed]="apartment.feed" >
                    <i tooltip="{{'ApartmentManagement.Feed.' + (apartment.feed|lowercase)|translate}}" placement="left" [class]="feedTooltipClass" [attr.data-feed]="apartment.feed"></i>
                    {{'ApartmentManagement.Feed.' + (apartment.feed|lowercase)|translate}}
                </td>
            </tr>
            <tr *ngIf="apartment.dssId">
                <th class="pe-2 text-end">{{'ApartmentManagement.Details.dss'|translate}}</th>
                <td [attr.data-apartment-dssid]="apartment.dssId">{{apartment.dssId}}</td>
            </tr>
            <tr>
                <th class="pe-2 text-end">{{'ApartmentManagement.Details.Apartment'|translate}}</th>
                <td class="force-wrap" [attr.data-apartment-id]="apartment.id">{{apartment.id}}</td>
            </tr>
            <tr>
                <th class="pe-2 text-end">{{'ApartmentManagement.Details.RelayLink'|translate}}</th>
                <td class="force-wrap" *ngIf="apartment.dssId" [attr.data-apartment-pagekite-link]="apartment.pagekiteLink">
                    <a href="//{{apartment.pagekiteLink}}" target="_blank">{{apartment.pagekiteLink}}</a>
                </td>
                <td class="force-wrap" *ngIf="!apartment.dssId" [attr.data-apartment-pagekite-link]="apartment.pagekiteLink">
                    {{apartment.pagekiteLink}}
                    <i tooltip="{{'ApartmentManagement.Details.RelayLinkNotActive'|translate}}" placement="left" class="fa fa-info-circle"></i>
                </td>
            </tr>
        </table>
    </div>
    <ng-container [ngTemplateOutlet]="controls" [ngTemplateOutletContext]="{apartment:apartment}"></ng-container>
    <button type="button" class="btn btn-light p-0 w-100 text-start no-border-radius" data-apartment-toggle-button
        (click)="toggleCollapseStateBackups()" [attr.aria-expanded]="!isCollapsedBackup" attr.aria-controls="backupid">
        <ng-container [ngTemplateOutlet]="backup" [ngTemplateOutletContext]="{apartment:apartment}"></ng-container>
    </button>
    <div id="backupid" *ngIf="!isCollapsedBackup">
        <ng-container [ngTemplateOutlet]="backupDownload"></ng-container>
    </div>
</div>

<ng-template #controls let-apartment='apartment'>
    <div class="d-sm-flex justify-content-end text-center text-sm-left p-2">
        <div class="p-1">
            <button [disabled]="apartment.inDeletionProcess" data-action="edit" class="btn btn-secondary btn-sm float-center ms-2" placement="left"
            (click)="router.navigate(['/apartment-management', { apartment: apartment.id, mode:'edit' }])">
                <i class="fa fa-edit"></i>
                <span class="ms-1">{{'ApartmentManagement.Controls.EditApartment'|translate}}</span>
            </button>
        </div>
        <div class="p-1">
            <button [disabled]="apartment.inDisconnectProcess || !apartment.dssId" data-action="disconnect" class="btn btn-secondary btn-sm float-center ms-2" placement="left"
            (click)="router.navigate(['/apartment-management', { apartment: apartment.id, mode:'disconnect' }])">
                <i class="fa fa-plug"></i>
                <span class="ms-1">{{'ApartmentManagement.Controls.DisconnectApartment'|translate}}</span>
            </button>
        </div>
        <div class="p-1">
            <button [disabled]="apartment.inDeletionProcess" data-action="delete" class="btn btn-secondary btn-sm float-center ms-2" placement="left"
            (click)="router.navigate(['/apartment-management', { apartment: apartment.id, mode:'delete' }])">
                <i class="fa fa-trash-o"></i>
                <span class="ms-1">{{'ApartmentManagement.Controls.DeleteApartment'|translate}}</span>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #backup let-apartment='apartment'>
    <div class="row justify-content-between p-3" [attr.data-state]="isCollapsedBackup ? 'collapsed' : 'expanded'">
        <div class="col-10 col-sm-11">

            <span [attr.data-apartment-name]="apartment.name">
                Backups
            </span>

            <i *ngIf="backupsLoading" class="fa fa-spinner fa-spin"></i>
        </div>

        <div class="col-2 col-sm-1 ms-auto text-end">
            <i [class]="isCollapsedBackup ? 'fa fa-chevron-down' : 'fa fa-chevron-up'" placement="right"></i>
        </div>

    </div>
</ng-template>

<ng-template #backupDownload>
    <ul class="list-group list-group-flush">
        <li class="list-group-item" *ngFor="let backup of backups.reverse();">
            <button class="btn btn-link p-0" (click)="downloadBackup(backup.filename, backup.newBackup)"><i class="fa fa-download pe-1"></i>{{backup.name}}</button>
        </li>
    </ul>
</ng-template>