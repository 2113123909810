import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PasswordMatchValidatorService {

  constructor() { }

  public validator(control: AbstractControl): ValidationErrors {
    if (!control || !control.get('password') || !control.get('passwordConfirmation')) {
      return null;
    }

    const password: string = control.get('password').value;
    const confirmPassword: string = control.get('passwordConfirmation').value;
    if (!confirmPassword || confirmPassword.length === 0) {
      return null;
    }

    if (password !== confirmPassword) {
      control.get('passwordConfirmation').setErrors({passwordMismatch: true});
    } else {
      control.get('passwordConfirmation').setErrors(null);
    }
    return null;
  }
}
