
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'AppInstanceManagement.Modal.Title'|translate}}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </div>
    <div class="modal-body">
        <p>{{'AppInstanceManagement.Modal.Body'|translate}}</p>
        <div class="text-end">
            <button type="button" class="btn btn-outline me-2" id="dsAppInstanceModalButtonCancel" (click)="bsModalRef.hide()">
                {{'AppInstanceManagement.Modal.ButtonNo'|translate}}
            </button>
            <button type="button" class="btn btn-danger" id="dsAppInstanceModalButtonSubmit" [disabled]="clicked ? true : null"
            (click)="revokeAppInstance(); clicked = true;">
                {{'AppInstanceManagement.Modal.ButtonYes'|translate}}
            </button>
        </div>
    </div>
