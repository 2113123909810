<div class="row justify-content-center">
    <div class="col-12 col-md-9 col-lg-8">
        <messages></messages>
        <div class="card my-3">
            <h4 class="card-header">
                {{'ChangeEmail.Title'|translate}}
            </h4>
            <div class="card-body">
                <form [formGroup]="changeEmailForm" (submit)="changeEmailAction()">
                    <div class="form-group">
                        {{'Profile.ChangeEmailModal.Info'|translate}}
                    </div>
                    <div class="form-group">
                        <label for="password">
                            {{'ChangeEmail.Password'|translate}}
                        </label>
                        <input #password class="form-control" id="password" type="password" formControlName="password" required>
                        <control-messages [control]="changeEmailForm.get('password')"></control-messages>
                    </div>
                    <div class="form-group">
                        <label for="password">
                            {{'ChangeEmail.Email'|translate}}
                        </label>
                        <input #email class="form-control" id="email" type="email" formControlName="email" required>
                        <control-messages [control]="changeEmailForm.get('email')"></control-messages>
                    </div>
                    <div class="form-group">
                        <label for="emailConfirmation">
                            {{'ChangeEmail.EmailConfirmation'|translate}}
                        </label>
                        <input #emailConfirmation class="form-control" id="emailConfirmation" type="email" formControlName="emailConfirmation" required>
                        <control-messages [control]="changeEmailForm.get('emailConfirmation')"></control-messages>
                    </div>
                    <div class="form-group form-check">
                        <input #dSSDisconnected id="dSSDisconnected" type="checkbox" formControlName="dSSDisconnected" value="true" required
                        [class]="'form-check-input' + (changeEmailForm.value.dSSDisconnected ? ' checked' : '')">
                        <label class="form-check-label" for="dSSDisconnected">
                            {{'ChangeEmail.DssDisconnected'|translate}}
                        </label>
                    </div>
                    <div class="mt-4">
                        <a class="btn btn-outline" id="dsChangeEmailComponentLinkCancel" [routerLink]="['/profile']">
                            {{'ChangeEmail.Controls.Cancel'|translate}}
                        </a>
                        <button class="btn btn-primary float-end" id="dsChangeEmailComponentButtonSubmit" type="submit" [disabled]="!changeEmailForm.valid">
                            {{'ChangeEmail.Controls.SubmitForm'|translate}}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
