<div class="row justify-content-center">
    <div class="col-12 col-md-9 col-lg-8">
        <messages></messages>
        <div class="card">
            <h4 class="card-header">{{'ChangePassword.Title'|translate}}</h4>
            <div class="card-body">
                <form [formGroup]="changePasswordForm" (submit)="changePasswordAction()">
                    <div class="form-group">
                        <label for="oldPassword">{{'ChangePassword.OldPassword'|translate}}</label>
                        <input #oldPasswordControl class="form-control" id="oldPassword" type="password" formControlName="oldPassword" required>
                        <control-messages [control]="changePasswordForm.get('oldPassword')"></control-messages>
                    </div>
                    <div class="form-group">
                        <label for="password">{{'ChangePassword.Password'|translate}}</label>
                        <input #passwordControl class="form-control" id="password" type="password" formControlName="password" required>
                        <control-messages [control]="changePasswordForm.get('password')"></control-messages>
                    </div>
                    <div class="form-group">
                        <label for="passwordConfirmation">{{'ChangePassword.PasswordConfirmation'|translate}}</label>
                        <input #passwordConfirmationControl class="form-control" id="passwordConfirmation" type="password" formControlName="passwordConfirmation" required>
                        <control-messages [control]="changePasswordForm.get('passwordConfirmation')"></control-messages>
                    </div>
                    <div class="mt-4">
                        <a class="btn btn-outline" id="dsChangePasswordComponentLinkCancel" [routerLink]="['/profile']">
                            {{'ChangePassword.Controls.Cancel'|translate}}
                        </a>
                        <button 
                            class="btn btn-primary float-end" 
                            id="dsChangePasswordComponentButtonSubmit" 
                            type="submit" 
                            [disabled]="!changePasswordForm.valid">
                            {{'ChangePassword.Controls.SubmitForm'|translate}}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
