import { enableProdMode } from '@angular/core';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

fetch(`assets/configuration/config.json`)
  .then(response => response.json())
  .then((response: any) => {
    let config;
    if (response.feed === 'dev') {
      config = environment.dev;
    }
    if (response.feed === 'staging') {
      config = environment.staging;
    }
    if (response.feed === 'prod') {
      config = environment.prod;
      enableProdMode();
    }

    platformBrowserDynamic([{ provide: FIREBASE_OPTIONS, useValue: config.firebaseConfig }])
      .bootstrapModule(AppModule)
      .catch(err => console.log(err));
  }).catch(err => console.error(err));
