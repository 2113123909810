<div>
    <div class="card my-3">
        <div class="card-body">
            <h2 class="card-title">{{'RemoteConnectivity.Failure.Title'|translate}}</h2>
            <div *ngIf="this.error === 'code_expired'" class="card-text limited-text-width">
                {{'RemoteConnectivity.Failure.CodeExpired'|translate}}
            </div>
            <div *ngIf="this.error === 'pagekite_failed'" class="card-text limited-text-width">
                {{'RemoteConnectivity.Failure.PagekiteFailed'|translate}}
            </div>
            <div *ngIf="this.error === 'other'" class="card-text limited-text-width">
                {{'RemoteConnectivity.Failure.Other'|translate}}
                <br/>
                {{'RemoteConnectivity.Failure.Mac'|translate}} {{mac}}
                <br/>
                {{'RemoteConnectivity.Failure.dSId'|translate}} {{dssId}}
            </div>
            <div *ngIf="this.error === 'default'" class="card-text limited-text-width">
                {{'RemoteConnectivity.Failure.Default'|translate}}
            </div>
        </div>
    </div>
</div>
