import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { ParameterService } from '../services/parameter.service';
import { SpinnerService } from '../services/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class AnonymousGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private params: ParameterService,
    private spinner: SpinnerService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user$.pipe(
      take(1),
      map(user => !user), // maps to boolean
      tap(async anonymous => {
        if (anonymous) {
          return;
        }

        this.params.setParametersFromParams(next.queryParams);
        if (!this.params.isRedirectAuthFlow()) {
          this.router.navigate(['/apartment-management']);
          return;
        }

        if (this.params.isRelogintrue()) {
          await this.auth.signOut();
          this.spinner.hide();
          return;
        }

        this.router.navigateByUrl('/consent' + this.params.getRequestQueryParametersString());
      })
    );
  }
}
