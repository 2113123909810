export class Message {
    id: string;
    translationIdentifier: string;
    values: object = null;
    type = 'info';
    dictionary: object = {};

    constructor() {
        this.id = Guid.newGuid();
    }
}

/* tslint:disable:no-bitwise */
class Guid {
    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}
