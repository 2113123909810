import { Component, OnInit, TemplateRef } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryService } from '../services/country.service';
import { Country } from '../models/country.model';
import { SalutationService } from '../services/salutation.service';
import { LanguageService } from '../services/language.service';
import { Language } from '../models/language.model';
import { UserProfile } from '../models/user.model';
import { Salutation } from '../models/salutation.model';
import { MessagesService } from '../services/messages.service';
import { SpinnerService } from '../services/spinner.service';
import { PhoneValidatorService } from '../services/phone-validator.service';
import { ProviderService } from '../services/provider.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { FirebaseFunctionsService } from '../services/firebase-functions.service';

interface FormData {
  email: FormControl<string>;
  signedUpNewsletter: FormControl<boolean>;
  language: FormControl<string>;
  salutation: FormControl<string>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  street: FormControl<string>;
  number: FormControl<string>;
  zipCode: FormControl<string>;
  city: FormControl<string>;
  country: FormControl<string>;
  phone: FormControl<string>;
}

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  isEmailVerified = true;
  profileForm: FormGroup<FormData>;
  bsModalRef: BsModalRef;
  email: string;
  language: string;
  formEditable = false;
  profileFormInitialState: UserProfile;
  providerIds: string[] = [];
  hasThirdPartyProvider = false;

  languages: Language[] = [];
  salutations: Salutation[] = [];
  countries: Country[] = [];

  constructor(
    public auth: AuthService,
    private formBuilder: FormBuilder,
    languageService: LanguageService,
    salutationService: SalutationService,
    countryService: CountryService,
    private modalService: BsModalService,
    private messageService: MessagesService,
    private router: Router,
    private spinner: SpinnerService,
    private phoneValidator: PhoneValidatorService,
    private providerService: ProviderService,
    private functions: FirebaseFunctionsService
  ) {
    this.languages = languageService.items;
    this.salutations = salutationService.items;
    this.countries = countryService.items;
    this.buildForm();
  }

  ngOnInit() {
    this.messageService.clear();
    this.providerService.getProviderIds().then(value => {
      this.providerIds = value;
      this.hasThirdPartyProvider = this.providerIds.length > 0;
    });
  }

  private async buildForm(): Promise<void> {
    this.spinner.show(true);

    const initialInputStringValue = { value: '', disabled: true };
    const initialInputBooleanValue = { value: false, disabled: true };
    this.profileForm = this.formBuilder.group({
      email: [{ ...initialInputStringValue }, []],
      signedUpNewsletter: [{ ...initialInputBooleanValue }, []],
      language: [{ ...initialInputStringValue }, [Validators.required]],
      salutation: [{ ...initialInputStringValue }, [Validators.required]],
      firstName: [{ ...initialInputStringValue }, [Validators.required]],
      lastName: [{ ...initialInputStringValue }, [Validators.required]],
      street: [{ ...initialInputStringValue }, [Validators.required]],
      number: [{ ...initialInputStringValue }, [Validators.required]],
      zipCode: [{ ...initialInputStringValue }, [Validators.required]],
      city: [{ ...initialInputStringValue }, [Validators.required]],
      country: [{ ...initialInputStringValue }, [Validators.required]],
      phone: [{ ...initialInputStringValue }, [Validators.pattern(/^$|^(\+?[0-9]{2,3})?[0-9]{6,}$/)]]
    }, { validators: this.phoneValidator.validator });

    const user = await this.auth.getUserFromStore();

    if (!user) {
      return;
    }

    const currentUser = this.auth.getCurrentFirebaseUser();

    // ToDo that looks off
    if (currentUser.emailVerified !== true && currentUser.providerData[0].providerId !== 'facebook.com') {
      this.isEmailVerified = false;
      this.email = currentUser.email;
    }

    if (currentUser.emailVerified) {
      if (!user.confirmed) {
        this.functions.confirmUserAccountEmail(currentUser.uid);
      }
    }

    let language = '';
    if (user.language) {
      language = user.language;
    }
    let country = '';
    if (user.country) {
      country = user.country;
    }

    this.profileForm.patchValue({
      email: user.email,
      signedUpNewsletter: user.signedUpNewsletter,
      language,
      salutation: user.salutation,
      firstName: user.firstName,
      lastName: user.lastName,
      street: user.street,
      number: user.number,
      zipCode: user.zipCode,
      city: user.city,
      country,
      phone: user.phone
    } as UserProfile);

    this.spinner.hide();
  }

  toggleEditForm() {
    for (const controlName in this.profileForm.controls) {
      if (controlName !== 'email') {
        this.formEditable
          ? this.profileForm.controls[controlName].enable()
          : this.profileForm.controls[controlName].disable();
      }
    }
  }

  enableFormEdition() {
    this.formEditable = true;
    this.toggleEditForm();
    this.profileFormInitialState = this.profileForm.value as UserProfile;
  }

  cancelFormEdition() {
    this.formEditable = false;
    this.toggleEditForm();
    this.profileForm.patchValue(this.profileFormInitialState);
    this.profileFormInitialState = null;
  }

  openChangeEmail() {
    this.router.navigate(['/change-email']);
  }

  async updateAccount(): Promise<void> {
    if (this.profileForm.invalid) {
      return;
    }

    this.spinner.show(true);

    const currentUser = this.auth.getCurrentFirebaseUser();
    try {
      await this.functions.updateUserAccount({
        uid: currentUser.uid,
        email: currentUser.email,
        signedUpNewsletter: this.profileForm.value.signedUpNewsletter,
        acceptedTermsAndConditions: true,
        acceptedPrivacyPolicy: true,
        language: this.profileForm.value.language,

        salutation: this.profileForm.value.salutation,
        firstName: this.profileForm.value.firstName,
        lastName: this.profileForm.value.lastName,
        street: this.profileForm.value.street,
        number: this.profileForm.value.number,
        zipCode: this.profileForm.value.zipCode,
        city: this.profileForm.value.city,
        country: this.profileForm.value.country,
        phone: this.profileForm.value.phone,
      });

      this.formEditable = false;
      this.toggleEditForm();
    }
    catch (error) {
      console.log(error);
      this.messageService.setErrorMessage('Profile.Error');
    }
    finally {
      this.spinner.hide();
    }
  }

  async deleteAccount(): Promise<void> {
    this.spinner.show();
    const currentUser = this.auth.getCurrentFirebaseUser();
    try {
      await this.functions.deleteUserAccount(currentUser.uid, await currentUser.getIdToken());
      this.messageService.setMessage('Profile.AccountDeleted');
      await this.auth.signOut();
    }
    catch (error) {
      console.log(error);
      this.messageService.setErrorMessage('Profile.AccountDeletionFailure', { apartmentId: error.error });
    }
    finally {
      this.spinner.hide();
    }
  }

  openDeleteAccountModal(template: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(template);
  }
}
