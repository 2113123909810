<div class="row justify-content-center">
    <div class="col-12 col-md-9 col-lg-8">
        <messages></messages>
        <div class="card">
            <h4 class="card-header"><span [translate]="'Consent.WelcomeMessage'" [translateParams]="{ firstName: this.firstName, lastName: this.lastName }"></span></h4>
            <div class="card-body">
                <p *ngIf="customGreeting">{{('CustomGreetings.' + customGreeting) | translate}}</p> 
                <ng-container *ngIf="!customGreeting">
                    <div class="form-group">{{'Consent.ExplanationText'|translate}}</div>
                    <ul class="list-group">
                        <li class="list-group-item" *ngFor="let item of scopes | async" [id]="item.key">
                            <b class="me-1">{{'Messages.Consent.' + item.key|translate}}</b>
                            {{'Messages.Consent.' + item.key + '.Sub'|translate}}
                        </li>
                    </ul>
                </ng-container>
                <div class="mt-4 text-end">
                    <a class="btn btn-link" id="dsConsentComponentButtonCancel" href="/" (click)="auth.signOut()">
                        {{'Consent.Controls.Cancel'|translate}}
                    </a>
                    <button class="btn btn-primary" id="dsConsentComponentButtonSubmit" (click)="this.submit()">
                        {{'Consent.Controls.SubmitForm'|translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
