import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'remote-connectivity-failure',
  templateUrl: './remote-connectivity-failure.component.html',
  styleUrls: ['./remote-connectivity-failure.component.scss']
})
export class RemoteConnectivityFailureComponent {
  error: string;
  mac: string;
  dssId: string;

  constructor(private route: ActivatedRoute) {
    this.error = this.route.snapshot.queryParams.error;
    this.mac = this.route.snapshot.queryParams.macAddress;
    this.dssId = this.route.snapshot.queryParams.dSId;
  }
}
