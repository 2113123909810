import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { ApartmentResponse } from '../models/apartment/ApartmentResponse';
import { ConnectionResponse } from '../remote-connectivity/ConnectionResponse';
import { IAttribute } from '../models/apigee/userattribute.model';
import { IUserAuthorize } from '../models/apigee/userauthorize.model';
import { IImplicitToken } from '../models/apigee/implicitToken.model';
import { AppInstanceResponse } from '../models/appinstance/AppInstanceResponse';
import { Environment, environment } from '../../environments/environment';
import { User } from '../models/user.model';
import { IPagekite } from '../models/pagekite.model';
import { BackupResponse } from '../models/apartment/BackupResponse';
import { Auth } from '@angular/fire/auth';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebaseFunctionsService {

  constructor(
    private auth: Auth,
    private http: HttpClient) { }

  private async headers(): Promise<HttpHeaders> {
    const idToken = await this.auth.currentUser.getIdToken();

    const headers = new HttpHeaders(
      {
        Authorization: 'Bearer ' + idToken
      }
    );
    // console.log(headers);

    return headers;
  }

  private getEnvironment(feed: string): Environment {
    switch (feed) {
      case 'dev':
        return environment.dev;
      case 'staging':
        return environment.staging;
      case 'prod':
        return environment.prod;
      default:
        throw new Error('No such enviroment: ' + feed);
    }
  }

  private async base(): Promise<string> {
    const response = await fetch(`assets/configuration/config.json`);
    const json = await response.json();
    const feed = json.feed;
    const env = this.getEnvironment(feed);
    return 'https://' + env.firebaseFunctionsRegion + '-' + env.firebaseConfig.projectId + '.cloudfunctions.net';
    // for local debuging
    // return 'http://localhost:5001/ds-account-dev/europe-west1';
  }

  private async get<T>(suburl: string): Promise<T> {
    const headers = await this.headers();
    const base = await this.base();
    return lastValueFrom(this.http.get<T>(base + suburl, { headers }));
  }

  private async post<T>(suburl: string, body: any): Promise<T> {
    const headers = await this.headers();
    const base = await this.base();
    return lastValueFrom(this.http.post<T>(base + suburl, body, { headers }));
  }

  private async put<T>(suburl: string, body: any): Promise<T> {
    const headers = await this.headers();
    const base = await this.base();
    return lastValueFrom(this.http.put<T>(base + suburl, body, { headers }));
  }

  private async delete<T>(suburl: string, params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  }): Promise<T> {
    const headers = await this.headers();
    const base = await this.base();
    return lastValueFrom(this.http.delete<T>(base + suburl, { headers, params }));
  }

  public getUserAccount(uid: string): Promise<User> {
    return this.get<User>(`/accounts/${uid}`);
  }

  public createUserAccount(user: User, options: any = {}): Promise<User> {
    const body = {
      data: {
        user,
        options
      }
    };

    return this.post<User>('/accounts/', body);
  }

  public async deleteUserAccount(uid: string, idToken: string): Promise<string> {
    const response = await this.delete('/accounts/', { idToken, userId: uid });
    console.log(response);
    if (response) {
      return response.toString();
    } else {
      return null;
    }
  }

  public updateUserAccount(user: any): Promise<User> {
    const body = {
      data: {
        user
      }
    };

    return this.put<User>('/accounts/', body);
  }

  public updateEmailInMsHub(newEmail: string, oldEmail: string): Promise<object> {
    return this.post('/accounts/updateEmailInMsHub?newEmail=' + newEmail + '&oldEmail=' + oldEmail, null);
  }

  public confirmUserAccountEmail(userUid: string): Promise<void> {
    return this.post<void>(`/accounts/${userUid}/emailVerification`, {});
  }

  public getApartments(): Promise<ApartmentResponse> {
    return this.get<ApartmentResponse>('/remoteconnectivity/apartments');
  }

  public getBackups(dsUid: string): Promise<BackupResponse> {
    return this.get<BackupResponse>('/remoteconnectivity/backups?dsUid=' + dsUid);
  }

  public async downloadBackup(filename: string, newBackup: boolean): Promise<any> {
    const headers = await this.headers();
    const base = await this.base();
    const url = base + '/remoteconnectivity/downloadBackup?filename=' + filename + '&newBackup=' + newBackup;
    return lastValueFrom(this.http.get(url, { headers, responseType: 'blob' }));
  }

  public createApartment(body: object): Promise<any> {
    return this.post('/remoteconnectivity/apartments', body);
  }

  public updateApartment(apartmentId: string, body: object): Promise<any> {
    return this.put('/remoteconnectivity/apartments/' + apartmentId, body);
  }

  public getDssConnection(dssId: string, feed: string): Promise<ConnectionResponse> {
    return this.get<ConnectionResponse>('/remoteconnectivity/dss-connections/' + dssId + '/' + feed);
  }

  public deleteDssConnection(dssId: string, feed: string): Promise<void> {
    return this.delete('/remoteconnectivity/dss-connections/' + dssId + '/' + feed);
  }

  public deleteApartment(apartmentId: string, idToken: string): Promise<void> {
    return this.delete('/remoteconnectivity/apartments/' + apartmentId + '/delete', { idToken });
  }

  public deleteApartmentDataFromApartmentDisconnect(apartmentId: string): Promise<void> {
    return this.delete('/remoteconnectivity/apartmentDisconnect/' + apartmentId);
  }

  public getAppInstances(): Promise<AppInstanceResponse> {
    return this.get<AppInstanceResponse>('/oauth2/appinstance');
  }

  public async revokeAppInstance(apartmentId: string | undefined, refreshToken: string): Promise<void> {
    let subUrl = '/internal/v2/appinstances?refreshToken=' + refreshToken;

    if (apartmentId) {
      subUrl += '&apartmentId=' + apartmentId;
    }

    return this.delete(subUrl);
  }

  public async validateUserCode(userCode: string, clientId: string, verificationCount: string): Promise<any> {
    const body = {
      user_code: userCode,
      verification_count: verificationCount,
      client_id: clientId
    };

    return this.post('/apigee/device/userverification', JSON.stringify(body));
  }

  public remoteConnectivityStatus(body: any): Promise<any> {
    const url = '/apigee/device/remoteConnectivityStatus';
    return this.post(url, JSON.stringify(body));
  }

  public async verifyUniqueMacAddressForFeed(dsUid: string, dssFeed: string, macAddress: string): Promise<any> {
    const body = {
      ds_uid: dsUid,
      dss_feed: dssFeed,
      mac_address: macAddress
    };

    return this.post('/remoteconnectivity/macAddress/', JSON.stringify(body));
  }

  public async authorizeUser(
    clientId: string,
    xDsTrackingId: string,
    appInstanceId: string,
    appInstanceName: string,
    redirectUri: string,
    scopes: string,
    responseType: string,
    state?: string,
  ): Promise<string> {
    const base = await this.base();
    let url = base + '/apigee/userauthorize'
      + '?client_id=' + clientId
      + '&appinstance_id=' + appInstanceId
      + '&appinstance_name=' + appInstanceName
      + '&redirect_uri=' + redirectUri
      + '&scopes=' + scopes
      + '&response_type=' + responseType;

    if (state != null) {
      url += '&state=' + state;
    }

    let headers = await this.headers();
    headers = headers.append('X-DS-TrackingID', xDsTrackingId);
    console.log(url);

    const userAuthorize = await lastValueFrom(this.http.get<IUserAuthorize>(url, { headers }));
    return userAuthorize.data.attributes.location;
  }

  public async getAttributesForUser(clientId: string, scopes: string): Promise<IAttribute> {
    let url = '/apigee/attributes?client_id=' + clientId;

    if (scopes) {
      url += 'scopes=' + scopes;
    }

    return this.get<IAttribute>(url);
  }

  public async implicitToken(
    clientId: string,
    xDsTrackingId: string,
    appInstanceId: string,
    appInstanceName: string,
    redirectUri: string,
    scopes: string,
    responseType: string,
    grantType: string
  ): Promise<string> {
    const base = await this.base();
    const url = base + '/apigee/token'
      + '?client_id=' + clientId
      + '&appinstance_id=' + appInstanceId
      + '&appinstance_name=' + appInstanceName
      + '&redirect_uri=' + redirectUri
      + '&scopes=' + scopes
      + '&response_type=' + responseType
      + '&grant_type=' + grantType;

    let headers = await this.headers();
    headers = headers.append('X-DS-TrackingID', xDsTrackingId);
    console.log(url);

    const implicitToken = await lastValueFrom(this.http.get<IImplicitToken>(url, { headers }));
    return implicitToken.data.attributes.location;
  }

  public async createPagekiteLink(dssId: string): Promise<IPagekite> {
    const url = '/remoteconnectivity/createPageKiteLink?dssId=' + dssId;
    return this.get<IPagekite>(url);
  }

  public async reestablishPagekiteLink(name: string, password: string, dssId: string, apartmentId: string): Promise<IPagekite> {
    const url = '/remoteconnectivity/reestablishPageKiteLink?name=' + name
      + '&password=' + password
      + '&dssId=' + dssId
      + '&apartmentId=' + apartmentId;

    return this.get<IPagekite>(url);
  }

  public async deletePagekiteLink(name: string, dssId: string, apartmentId: string): Promise<IPagekite> {
    const url = '/remoteconnectivity/deletePageKiteLink?name=' + name + '&dssId=' + dssId + '&apartmentId=' + apartmentId;
    return this.delete<IPagekite>(url);
  }
}
