import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Language } from '../models/language.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  languageCodes: string[] = ['de', 'en', 'fr', 'nl'];
  items: Language[] = [];

  constructor(
    private translate: TranslateService
  ) {
    this.languageCodes
      .forEach(element => {
        const language = new Language();
        language.uid = element;
        this.items.push(language);
      });

    this.prepareSortedItems();
    translate.onLangChange.subscribe(() => {
      this.prepareSortedItems();
    });
  }

  async prepareSortedItems() {
    const translate = this.translate;

    for (const language of this.items) {
      language.name = await firstValueFrom(translate.get('Language.' + language.uid));
    }

    this.items.sort((a, b) => {
      const lang = translate.currentLang ? translate.currentLang.substring(0, 2) : 'en';
      return a.name.localeCompare(b.name, lang);
    });
  }
}
