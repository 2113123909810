import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private visible = new BehaviorSubject<boolean>(false);
  private transparent = new BehaviorSubject<boolean>(false);
  private layers = 0;

  public readonly isVisible = this.visible.asObservable();
  public readonly isTransparent = this.transparent.asObservable();

  constructor(router: Router) {
    router.events.pipe(filter(event => {
      return event instanceof NavigationStart ||
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError;
    }))
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          this.show();
          return;
        }

        this.hide();
      });
  }

  show(transparent: boolean = false) {
    this.layers++;
    this.transparent.next(transparent);
    this.visible.next(this.layers > 0);
  }

  hide() {
    if (this.layers > 0) {
      this.layers--;
    }
    this.visible.next(this.layers > 0);
  }
}
