import { Component } from '@angular/core';

@Component({
  selector: 'deprecated',
  templateUrl: './deprecated.component.html',
  styleUrls: ['./deprecated.component.scss']
})
export class DeprecatedComponent {

  constructor() {
  }
}
