import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

export interface Param {
    key: string;
    value: string;
}

@Injectable({
    providedIn: 'root'
})
export class ParameterService {
    private container: Param[] = [];

    constructor(
        private activatedRoute: ActivatedRoute
    ) {
        this.setParametersFromActivatedRoute();
    }

    public setParametersFromParams(params: Params) {
        this.addParameterToContainer(params, 'redirect_uri');
        this.addParameterToContainer(params, 'state');
        this.addParameterToContainer(params, 'appinstance_id');
        this.addParameterToContainer(params, 'appinstance_name');
        this.addParameterToContainer(params, 'app_name');
        this.addParameterToContainer(params, 'app_logo_url');
        this.addParameterToContainer(params, 'client_id');
        this.addParameterToContainer(params, 'response_type');
        this.addParameterToContainer(params, 'scopes');
        this.addParameterToContainer(params, 'relogin');
        this.addParameterToContainer(params, 'state');
        this.addParameterToContainer(params, 'greeting');
    }

    public addParameterToContainer(params: Params, key: string) {
        const paramValue = params[key];

        if (!paramValue || this.contains(key)) {
            return;
        }
        this.container.push({ key, value: paramValue });
    }

    public setParametersFromActivatedRoute() {
        this.setParametersFromParams(this.activatedRoute.snapshot.queryParams);

        if (!this.isRedirectAuthFlow()) {
            this.activatedRoute.queryParams.subscribe(params => {
                this.setParametersFromParams(params);
            });
        }
    }

    public getRequestQueryParametersString(additionalParameters: Param[] = null): string {
        if (!this.isRedirectAuthFlow()) {
            return '';
        }

        let parametersString =
            this.getRequestQueryParameterString('appinstance_id', true)
            + this.getRequestQueryParameterString('appinstance_name')
            + this.getRequestQueryParameterString('app_name')
            + this.getRequestQueryParameterString('app_logo_url')
            + this.getRequestQueryParameterString('response_type')
            + this.getRequestQueryParameterString('client_id')
            + this.getRequestQueryParameterString('scopes')
            + this.getRequestQueryParameterString('relogin')
            + this.getRequestQueryParameterString('state')
            + this.getRequestQueryParameterString('redirect_uri')
            + this.getRequestQueryParameterString('greeting');

        if (additionalParameters) {
            for (const param of additionalParameters) {
                if (!this.get(param.key)) {
                    parametersString += '&' + param.key + '=' + param.value;
                }
            }
        }

        return parametersString;
    }

    public isRedirectAuthFlow(): boolean {
        if (this.contains('redirect_uri') && this.contains('client_id')) {
            return true;
        } else {
            return false;
        }
    }
    public isRelogintrue(): boolean {
        if (this.contains('relogin')) {
            if (this.get('relogin').value === 'true' || this.get('relogin').value === '1') {
                return true;
            }
        }
        return false;
    }

    public contains(key: string): boolean {
        if (this.get(key)) {
            return true;
        } else {
            return false;
        }
    }

    public get(key: string): Param {
        const values = this.container.filter(param => param.key === key);
        if (values.length === 1) {
            return values[0];
        }
        return null;
    }

    private getRequestQueryParameterString(key: string, first: boolean = false, ignoreNull: boolean = true): string {
        const param = this.get(key);

        if (ignoreNull && !param) {
            return '';
        }

        let result = '';

        if (first) {
            result += '?';
        } else {
            result += '&';
        }

        return result + key + '=' + param.value;
    }
}
