import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../services/spinner.service';
import { AuthService } from '../services/auth.service';
import { ParameterService } from '../services/parameter.service';
import { Scope } from '../models/scope.model';
import { Observable, firstValueFrom, of } from 'rxjs';
import { FirebaseFunctionsService } from '../services/firebase-functions.service';
import { RedirectService } from '../services/redirect.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {
  firstName: string;
  lastName: string;
  email: string;
  xDsTrackingId: string;
  customGreeting: string;

  scopes: Observable<Scope[]>;
  scopeKeys: string[];

  constructor(
    public auth: AuthService,
    private params: ParameterService,
    private spinner: SpinnerService,
    private functions: FirebaseFunctionsService,
    private translate: TranslateService,
    private redirect: RedirectService
  ) {
    this.spinner.show();
    this.buildUI();
  }

  public ngOnInit() {
    this.initiateFlowWithApigee();
  }

  public async submit() {
    this.spinner.show(true);

    this.auth.user$.subscribe(async _ => {
      const scopes = await firstValueFrom(this.scopes);
      let scopeString = '';
      scopes.forEach(scope => scopeString += ',' + scope.key);
      let state = null;
      if (this.params.get('state') != null) {
        state = this.params.get('state').value;
      }
      scopeString = scopeString.substring(1);

      if (this.params.get('response_type').value === 'token') {
        const grantType = 'implicit';
        const redirectUri = await this.functions.implicitToken(
          this.params.get('client_id').value,
          this.xDsTrackingId,
          this.params.get('appinstance_id').value,
          this.params.get('appinstance_name').value,
          this.params.get('redirect_uri').value,
          '[' + scopeString + ']',
          this.params.get('response_type').value,
          grantType
        );

        this.redirect.ToClientUri(redirectUri);

      }
      else {
        const redirectUri = await this.functions.authorizeUser(
          this.params.get('client_id').value,
          this.xDsTrackingId,
          this.params.get('appinstance_id').value,
          this.params.get('appinstance_name').value,
          this.params.get('redirect_uri').value,
          '[' + scopeString + ']',
          this.params.get('response_type').value,
          state
        );

        this.redirect.ToClientUri(redirectUri);
      }
    });
  }

  private buildUI(): void {
    this.auth.user$.subscribe(u => {
      if (u == null) {
        return;
      }

      this.firstName = u.firstName;
      this.lastName = u.lastName;
      this.email = u.email;
    });
  }

  private async initiateFlowWithApigee() {
    const clientId = this.params.get('client_id').value;
    let scopes: string = null;

    if (this.params.contains('scopes')) {
      scopes = this.params.get('scopes').value;
    }
    let state = null;
    if (this.params.get('state') != null) {
      state = this.params.get('state').value;
    }
    const getAttributesModel = await this.functions.getAttributesForUser(clientId, scopes);
    const intersectionScopes = getAttributesModel.data.attributes.scopes.toString();
    this.xDsTrackingId = getAttributesModel.data.attributes.xDsTrackingId;

    if (!getAttributesModel.data.attributes.isTrusted) {
      try {

        this.scopeKeys = intersectionScopes
          .split(' ');

      } catch {
        this.scopeKeys = [];
      }

      this.prepareSortedItems();

      this.translate.onLangChange.subscribe((e: LangChangeEvent) => {
        this.prepareSortedItems();
      });

      this.spinner.hide();
      return;
    }

    if (this.params.contains('greeting')) {
      this.customGreeting = this.params.get('greeting').value;
    }

    if (this.params.get('response_type').value === 'token') {
      const grantType = 'implicit';
      const redirectUri = await this.functions.implicitToken(
        this.params.get('client_id').value,
        this.xDsTrackingId,
        this.params.get('appinstance_id').value,
        this.params.get('appinstance_name').value,
        this.params.get('redirect_uri').value,
        '[' + intersectionScopes + ']',
        this.params.get('response_type').value,
        grantType,
      );

      this.redirect.ToClientUri(redirectUri);

    }
    else {
      const redirectUri = await this.functions.authorizeUser(
        this.params.get('client_id').value,
        this.xDsTrackingId,
        this.params.get('appinstance_id').value,
        this.params.get('appinstance_name').value,
        this.params.get('redirect_uri').value,
        '[' + intersectionScopes + ']',
        this.params.get('response_type').value,
        state
      );

      this.redirect.ToClientUri(redirectUri);
    }
  }

  async prepareSortedItems() {
    const scopes: Scope[] = [];
    const translate = this.translate;

    for (const key of this.scopeKeys) {
      const scope: Scope = {
        key,
        name: await firstValueFrom(translate.get('Scope.' + key))
      };
      scopes.push(scope);
    }

    scopes.sort((a, b) => {
      const lang = translate.currentLang ? translate.currentLang.substring(0, 2) : 'en';
      return a.name.localeCompare(b.name, lang);
    });

    this.scopes = of(scopes);
  }
}
