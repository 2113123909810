import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { MessagesService } from '../services/messages.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PasswordMatchValidatorService } from '../services/password-match-validator.service';
import { FirebaseFunctionsService } from '../services/firebase-functions.service';
import { SpinnerService } from '../services/spinner.service';

interface VerifyCodeFormData {
  email: FormControl<string>;
  password: FormControl<string>;
}

interface ResetPasswordFormData {
  email: FormControl<string>;
  password: FormControl<string>;
  passwordConfirmation: FormControl<string>;
}

@Component({
  selector: 'verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {

  public verifyCodeForm: FormGroup<VerifyCodeFormData>;
  public unsupportedAction = false;
  public invalidRequest = false;
  public verifyEmail = false;
  public resetPassword = false;
  public recoverEmail = false;

  public resetPasswordForm: FormGroup<ResetPasswordFormData>;
  private verifyEmailAdress: string;

  private code: string;

  constructor(
    private fireAuth: AngularFireAuth,
    private messageService: MessagesService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private passwordMatch: PasswordMatchValidatorService,
    public translate: TranslateService,
    private functions: FirebaseFunctionsService,
    private spinner: SpinnerService,
  ) {
    const mode = this.route.snapshot.queryParamMap.get('mode') || '';
    this.code = this.route.snapshot.queryParamMap.get('oobCode') || '';

    switch (mode) {
      case 'verifyEmail':
        this.verifyEmail = true;
        fireAuth.signOut();

        fireAuth.checkActionCode(this.code).then(result => {
          this.verifyEmailAdress = result.data.email;
          if (result.operation !== 'VERIFY_EMAIL') {
            this.unsupportedAction = true;
            this.verifyEmail = false;
          }
        });

        break;
      case 'resetPassword':
        this.buildResetPasswordForm();
        this.resetPassword = true;
        break;
      case 'recoverEmail':
        this.recoverEmail = true;
        break;
      default:
        this.unsupportedAction = true;
        break;
    }
  }

  ngOnInit(): void {
    if (!this.verifyEmail) {
      return;
    }

    this.verifyCodeForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  private buildResetPasswordForm() {
    this.resetPasswordForm = this.formBuilder.group({
      email: [{ disabled: true, value: '' }],
      password: ['',
        [
          Validators.pattern(/\d/),
          Validators.pattern(/[a-z]/),
          Validators.pattern(/[A-Z]/),
          Validators.pattern(/[,.!"§$%&\/\\()=`´-]/),
          Validators.minLength(6)
        ]
      ],
      passwordConfirmation: ['', [Validators.required]]
    }, {
      validators: this.passwordMatch.validator
    });

    this.getEmailAddressFromCode().then(result => {
      this.resetPasswordForm.patchValue({ email: result });
    });
  }

  public async verifyAction() {
    this.spinner.show();
    try {
      const currentUser = await this.fireAuth.signInWithEmailAndPassword(this.verifyCodeForm.value.email, this.verifyCodeForm.value.password);
      const uid = currentUser.user.uid;
      const email = currentUser.user.email;

      if (email !== this.verifyEmailAdress) {
        this.messageService.setMessage('Verify.InvaildEmailForCode');
        this.fireAuth.signOut();
        return;
      }

      await this.fireAuth.applyActionCode(this.code);
      await this.functions.confirmUserAccountEmail(uid);

      currentUser.user.reload();

      this.router.navigate(['/']);
    }
    catch (error) {
      if (error.code === 'auth/wrong-password') {
        this.messageService.setErrorMessage('Auth.WrongPassword');
      } else if (error.code === 'auth/too-many-requests') {
        this.messageService.setErrorMessage('Auth.TooManyRequests');
      } else if (error.code === 'auth/expired-action-code') {
        this.messageService.setErrorMessage('Verify.ExpiredActionCode');
      } else if (error.code === 'auth/invalid-action-code') {
        this.messageService.setErrorMessage('Verify.InvalidActionCode');
      } else if (error.code === 'auth/user-disabled') {
        this.messageService.setErrorMessage('Verify.UserDisabled');
      } else if (error.code === 'auth/user-not-found') {
        this.messageService.setErrorMessage('Verify.UserNotFound');
      }
      else {
        console.log(error);
        this.messageService.setErrorMessage('Verify.Failed');
      }
    }
    finally {
      this.spinner.hide();
    }
  }

  async getEmailAddressFromCode(): Promise<string> {
    try {
      const result = await this.fireAuth.verifyPasswordResetCode(this.code);
      return result;
    }
    catch (error) {
      switch (error.code) {
        case 'auth/expired-action-code':
          this.messageService.setMessage('ResetPassword.ExpiredActionCode');
          break;
        case 'auth/invalid-action-code':
          this.messageService.setMessage('ResetPassword.InvalidActionCode');
          break;
        case 'auth/user-disabled':
          this.messageService.setMessage('ResetPassword.UserDisabled');
          break;
        case 'auth/user-not-found':
          this.messageService.setMessage('ResetPassword.UserNotFound');
          break;
        default:
          console.log(error);
          this.messageService.setMessage('ResetPassword.Failed');
      }

      this.invalidRequest = true;
    }
  }

  public async resetPasswordAction() {
    try {
      await this.fireAuth.confirmPasswordReset(this.code, this.resetPasswordForm.get('password').value);
      this.messageService.setMessage('ResetPassword.Completed');
      await this.router.navigate(['/signin']);
    }
    catch (error) {
      switch (error.code) {
        case 'auth/expired-action-code':
          this.messageService.setMessage('ResetPassword.ExpiredActionCode');
          break;
        case 'auth/invalid-action-code':
          this.messageService.setMessage('ResetPassword.InvalidActionCode');
          break;
        case 'auth/user-disabled':
          this.messageService.setMessage('ResetPassword.UserDisabled');
          break;
        case 'auth/user-not-found':
          this.messageService.setMessage('ResetPassword.UserNotFound');
          break;
        case 'auth/weak-password':
          this.messageService.setMessage('ResetPassword.WeakPassword');
          break;
        default:
          console.log(error);
          this.messageService.setMessage('ResetPassword.Failed');
      }

      this.invalidRequest = true;
    }
  }
}
